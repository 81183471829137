<template>
  <ProjectDetailItemEditCard confirmation-message="Project id werd succesvol gewijzigd" title="Bewerk project id"
    update-store-action="projectsModule/updateFunctionalId"
    :update-store-action-payload="{ newId: newId, projectId: projectId, clientId: clientId }" :projectId="projectId"
    :clientId="clientId" :promises.sync="promises">
    <template v-slot:form-fields>
      <v-row>
        <v-col cols="10" md="3">
          <FunctionalIdInput 
            v-model="newId"
            :suggestion="lastFetchedFunctionalIdSuggestion"
            label="Project id" prefix="P-"
            required
            :rules="functionalIdRules" />
        </v-col>
      </v-row>
    </template>
  </ProjectDetailItemEditCard>
</template>

<script>
import ProjectDetailItemEditCard from "@/views/project/forms/ProjectDetailItemEditCard.vue"
import routeNames from "@/router/RouteNames";
import { getRequiredRules, getStartsWithRules, getMaxStringSizeRules, getMinStringSizeRules } from "@/shared/utils/inputRulesUtils.js"
import FunctionalIdInput from "../../../components/shared/fields/FunctionalIdInput.vue"

export default {
  name: "ProjectDetailEditFunctionalId",
  props: {
    projectId: {
      type: String,
      required: true
    },
    clientId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      routeNames,
      projectName: null,
      functionalId: null,
      newId: null,
      promises: [
        this.$store.dispatch('projectsModule/fetchBasicProject', { clientId: this.clientId, projectId: this.projectId })
          .then((result) => { this.projectName = result.projectName; this.newId = result.functionalId, this.functionalId = result.functionalId })
      ],
      lastFetchedFunctionalIdSuggestion: null,
      functionalIdRules: [
        ...getRequiredRules('Id van het project moet ingevuld worden'),
        ...getStartsWithRules('P-', 'Id van een project moet beginnen met P-'),
        ...getMinStringSizeRules(3, 'Id van een project moet minimaal 1 teken bevatten'),
        ...getMaxStringSizeRules(6, 'Id van een project mag maximaal 4 tekens bevatten')
      ],
    }
  },
  components: {
    ProjectDetailItemEditCard,
    FunctionalIdInput,
  },
  methods: {
    getRequiredRules,
    getStartsWithRules,
    getMinStringSizeRules,
    getMaxStringSizeRules,
    fetchGeneratedFunctionalId() {
      if (this.projectName) {
        this.$store.dispatch("projectsModule/getFunctionalIdSuggestions", { clientId: this.clientId, projectId: this.projectId, seed: this.projectName, existingId: this.functionalId })
          .then(result => {
            this.lastFetchedFunctionalIdSuggestion = result
          })
      }
    },
    resetToGeneratedFunctionalId() {
      if (this.lastFetchedFunctionalIdSuggestion) {
        this.newId = this.lastFetchedFunctionalIdSuggestion
      } else {
        this.fetchGeneratedFunctionalId()
      }
    },
    validateNewId(value) {
      this.$nextTick(() => {
        // Prevent external overrides by using `$nextTick` ( make sure Vue has updated the DOM)
        if (!value || value.trim() === "") {
          this.newId = "P-";
        } else {
          value = value.toUpperCase();
          if (!value.startsWith("P-")) {
            // If the prefix is missing, enforce it
            this.newId = "P-" + value.replace(/^P-*/, "");
          } else {
            // Otherwise, keep the valid value
            this.newId = value;
          }
        }
      });
    }
  },
  computed: {
    showGenerateFunctionalIdButton() {
      return this.lastFetchedFunctionalIdSuggestion !== this.newId
    }
  },
  watch: {
    projectName: {
      deep: true,
      immediate: true,
      handler() {
        if (this.projectName) {
          this.fetchGeneratedFunctionalId()
        }
      }
    },
  }
}
</script>