<template>
  <PromiseContainer :promises.sync="promises">
    <div v-if="getMaxAmountOfMonths === 0">
      <v-banner
        class="banner-orange"
        rounded
      >
        Uw abonnement is nog geldig voor 24 maanden en kan niet verder verlengd worden
      </v-banner>
    </div>
    <v-form
      v-if="getMaxAmountOfMonths !== 0"
      v-model="validAmountOfMonths"
      @submit.prevent
    >
      <v-row>
        <v-col
          cols="12"
          lg="8"
          xl="5"
        >
          <v-text-field
            v-model="amountOfMonths"
            persistent-placeholder
            label="Aantal maanden"
            autofocus
            required
            type="number"
            min="1"
            :max="getMaxAmountOfMonths"
            :rules="amountOfMonthsRules"
            @input="onMonthsUpdate"
          />
        </v-col>
        <v-col
          cols="12"
          lg="8"
          xl="5"
        >
          <v-text-field
            v-model="computedSubscription.totalSeats"
            persistent-placeholder
            label="Aantal gebruikers"
            autofocus
            required
            type="number"
            min="1"
            :rules="amountOfUsersRules"
            @input="onUsersUpdate"
          />
        </v-col>
      </v-row>
      <p>
        Kostprijs per gebruiker <span class="float-right">€ {{ subscription?.price?.toFixed(2) }}</span>
        <br>
        Totale kost {{ computedSubscription.totalSeats }} {{ computedSubscription.totalSeats > 1 ? 'gebruikers' : 'gebruiker' }} <span class="float-right">€ {{ getMonthlyCost?.toFixed(2) }}</span>
        <br>
        <br>
        <strong>Direct te betalen:</strong> 
        <br>
        Verlenging periode {{ applyDateFormat(getExtendStartDate) }} tot {{ applyDateFormat(endDate) }}
        <br>
        {{ amountOfMonths }} {{ amountOfMonths > 1 ? 'maanden' : 'maand' }} x €{{ getMonthlyCost?.toFixed(2) }} (excl. {{ vatPercentage }}% btw)<span class="float-right">€ {{ expectedPrice?.toFixed(2) }}</span>
      </p>
      <v-divider />
      <payment-selection
        v-model="paymentMethod"
        class="mb-3"
      />
      <EulaCheckbox
        v-model="eulaAccepted"
        class="mb-3"
      />
      <v-btn
        :disabled="!paymentMethod || !validAmountOfMonths || !eulaAccepted"
        color="secondary"
        class="text-none mr-1"
        @click="buyExtensionOrRenewal"
      >
        Betaal
      </v-btn>
      <BasicToolTip
        icon="mdi-alert-circle-outline"
        color="red"
        description="Indien u het aantal gebruikers van uw huidig abonnement wilt wijzigen, is dit mogelijk bij: 'Extra gebruikers toevoegen'"
      />
    </v-form>
  </PromiseContainer> 
</template>

<script>
import PaymentSelection from "@/components/payment/PaymentSelection";
import EulaCheckbox from "@/components/shared/fields/EulaCheckbox.vue";
import publicIp from "public-ip";
import store from "@/store";
import {getVatPercentage} from "@/shared/utils/priceUtils";
import {addDays, addMonths, addYears, differenceInMonths} from "date-fns";
import PromiseContainer from "@/components/shared/PromiseContainer.vue";
import { applyDateFormat } from "@/shared/utils/dateUtils"
import BasicToolTip from "@/components/shared/actions/BasicToolTip"


export default {
  name: "SubscriptionExtend",
  components: { PaymentSelection, EulaCheckbox, PromiseContainer, BasicToolTip },
  props: {
    subscription: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
            validAmountOfMonths: false,
            paymentMethod: null,
            eulaAccepted: false,
            amountOfMonths: 1,
            expectedPrice: 0.0,
            promises: [],
            endDate: null
    }
  },
  computed: {
      computedSubscription: {
        get() {
          return this.subscription
        },
        set(newValue) {
          this.$emit('update:subscription', newValue)
        }
      },
    vatPercentage() {
            return getVatPercentage(this.countryCode)
        },
        
        getMaxAmountOfMonths() {
            if (this.isExtension) {
                // if you have a running subscription, calculate the difference between the endDate of the subscription and the current date 2 years from now
                let difference = differenceInMonths(addYears(new Date(), 2), new Date(this.endDate))
                if (difference > 0 && difference < 25) {
                    // if the difference is between 0 and 25, you can extend for 1 to difference amount of months with a max of 12 months
                    return difference > 12 ? 12 : difference
                } else {
                    // otherwise you can't extend and get a warning that your current subscription is running for longer than 2 years from now.
                    return 0
                }
            } else {
                // if your last subscription has ended, you can renew your subscription for a maximum of 12 months.
                return 12
            }
        },
        amountOfMonthsRules() {
            const rules = []
            rules.push(v => v > 0 || 'Abonnement moet ten minste verlengd worden voor 1 maand')
            if (this.amountOfMonths > this.getMaxAmountOfMonths) {
                rules.push(v => v <= this.getMaxAmountOfMonths || this.isExtension ? 'Abonnement kan maximaal voor ' + this.getMaxAmountOfMonths + ' maanden verlengd worden' : 'Een nieuw abonnement kan maximaal voor ' + this.getMaxAmountOfMonths + ' maanden genomen worden')
            }
            return rules
        },
        amountOfUsersRules() {
            const rules = []
            rules.push(v => v > 0 || 'Abonnement moet ten minste 1 gebruiker hebben')
            return rules
        },
        getMonthlyCost() {
            return (this.computedSubscription?.price * this.computedSubscription?.totalSeats)
        },
        getExtendStartDate() {
          return addDays(new Date(this.computedSubscription?.endDate), 1)
        }
  },
watch: {
  computedSubscription: {
    immediate: true,
    handler() {
      this.recalculateEndDate()
      this.recalculatePrice()
    }
  }
},
  methods:  {
    applyDateFormat,
    onMonthsUpdate() {
      this.recalculateEndDate()
      this.recalculatePrice()
    },
    onUsersUpdate() {
      this.recalculatePrice()
    },
    recalculateEndDate() {
        this.endDate = addMonths(this.getExtendStartDate, this.amountOfMonths) 
    },
    recalculatePrice() {
            if(!!this.amountOfMonths && this.amountOfMonths > 0 && !!this.computedSubscription?.totalSeats && this.computedSubscription?.totalSeats > 0) {
                this.$store.dispatch('paymentModule/calculatePriceForExtensionOrRenewal', {amountOfMonths: this.amountOfMonths, seats: this.computedSubscription?.totalSeats})
                  .then((success) => this.expectedPrice = success?.data)
            }
        },
    buyExtensionOrRenewal() {
            // checking if it was a trial to paid extension happens in backend
            this.promises.push(publicIp.v4()
                .then((result) => this.ipAddress = result)
                .catch(() => this.ipAddress = "192.168.0.1")
                .finally(() => {
                    const extendOrRenewalPaymentRequest = {
                        organizationId: store.getters["authModule/getOrganizationId"],
                        amountOfMonths: this.amountOfMonths,
                        seats: this.computedSubscription?.totalSeats,
                        ipAddress: this.ipAddress,
                        expectedPrice: this.expectedPrice,
                        paymentMethod: this.paymentMethod,
                        successReturnUrl: process.env.VUE_APP_PAYMENT_EXTENSION_URL_SUCCESS +
                            "&price=" + this.expectedPrice +
                            "&organizationId=" + this.$store.getters["authModule/getOrganizationId"] +
                            "&source=" + (this.isExtension ? "extension" : "renewal") +
                            "&paymentMethod=" + this.paymentMethod +
                            "&amount=" + this.amountOfMonths + 
                            "&seats=" + this.computedSubscription?.totalSeats, 
                        cancelReturnUrl: process.env.VUE_APP_PAYMENT_EXTENSION_URL_CANCEL,
                        failureReturnUrl: process.env.VUE_APP_PAYMENT_EXTENSION_URL_FAILURE
                    };                    
                    this.promises.push(this.$store.dispatch("paymentModule/extendOrRenewalPaymentRequest", extendOrRenewalPaymentRequest)
                        .then((result) => window.location.href = result))
                })
            );
        },
  }
}
</script>