import axiosInstance, { addPlainTextHeader, addMultiPartFormDataHeader } from "@/store/axios.instance";
import axios from "axios";

export default {
    namespaced: true,
    state() {
        return {
            project: null,

        }
    },
    mutations: {
        setProject(state, project) {
            state.project = project
        }
    },
    actions: {
        fetchProject({ commit, rootGetters }, data) {
            return axiosInstance.get(`/clients/${data.clientId}/projects/${data.projectId}`, rootGetters["authModule/getUrlConfig"])
                .then(result => {
                    commit('setProject', result?.data)
                    return result?.data
                })
        },
        fetchBasicProject({ commit, rootGetters }, data) {
            return axiosInstance.get(`/clients/${data.clientId}/projects/${data.projectId}/basic`, rootGetters["authModule/getUrlConfig"])
                .then(result => {
                    commit('setProject', result?.data)
                    return result?.data
                })
        },
        fetchProjectInformation({ rootGetters }, data) {
            return axiosInstance.get(`/clients/${data.clientId}/projects/${data.projectId}/information`, rootGetters["authModule/getUrlConfig"])
                .then(result => result?.data)
        },
        updateProjectInformation({ rootGetters }, data) {
            return axiosInstance.put(`/clients/${data.clientId}/projects/${data.projectId}/information`, data.projectInformation, rootGetters["authModule/getUrlConfig"])
        },
        fetchContactInformation({ rootGetters }, data) {
            return axiosInstance.get(`/clients/${data.clientId}/projects/${data.projectId}/contact-information`, rootGetters["authModule/getUrlConfig"])
                .then(result => result?.data)
        },
        updateContactInformation({ rootGetters }, data) {
            return axiosInstance.put(`/clients/${data.clientId}/projects/${data.projectId}/contact-information`, data.contactInformation, rootGetters["authModule/getUrlConfig"])
        },
        fetchImages({ rootGetters }, data) {
            return axiosInstance.get(`/clients/${data.clientId}/projects/${data.projectId}/images?page=${data.page || ''}&count=${data.count || ''}`, rootGetters["authModule/getUrlConfig"])
                .then(result => result?.data)
        },
        uploadImages({ rootGetters }, data) {
            //This loop is needed, otherwise the images aren't binary anymore and the backend can't read them
            const formData = new FormData();
            for (let i = 0; i < data.images.length; i++) {
                formData.append('files[]', data.images[i])
            }
            return axiosInstance.post(`/clients/${data.clientId}/projects/${data.projectId}/images`, formData, addMultiPartFormDataHeader(rootGetters["authModule/getUrlConfig"]))
        },
        updateImages({ rootGetters }, data) {
            return axiosInstance.put(`/clients/${data.clientId}/projects/${data.projectId}/images`, data.images, rootGetters["authModule/getUrlConfig"])
        },
        downloadImages(_, data) {
            let promises = []
            for (let index = 0; index < data.images.length; index++) {
                const url = data.images[index].replace(/%2F/gi, "/");
                const sasUrl = url + "?" + data.sasKey
                promises.push(
                    axios({
                        url: sasUrl, // Download File URL Goes Here
                        method: 'GET',
                        responseType: 'blob',
                    }).then((res) => {
                        var FILE = window.URL.createObjectURL(new Blob([res?.data]))
                        var link = document.createElement("a")
                        link.download = url.substring(url.lastIndexOf('/') + 1)
                        link.href = FILE
                        document.body.appendChild(link)
                        link.click()
                        document.body.removeChild(link)
                    })
                )
            }
            return Promise.all(promises)
        },
        deleteImages({ rootGetters }, data) {
            return axiosInstance.post(`/clients/${data.clientId}/projects/${data.projectId}/images/delete`, data.images, rootGetters["authModule/getUrlConfig"])
        },
        createProject({ dispatch, rootGetters }, projectDTO) {
            return axiosInstance.post(`/clients/${projectDTO.clientId}/projects`, projectDTO, rootGetters["authModule/getUrlConfig"])
                .then(result => {
                    dispatch('clientsModule/fetchNotArchivedClients', null, { root: true })
                    return result?.data
                })
        },
        updateProject({ dispatch, rootGetters }, data) {
            return axiosInstance.put(`/clients/${data.clientId}/projects/`, data.project, rootGetters["authModule/getUrlConfig"])
                .then(() => dispatch('clientsModule/fetchNotArchivedClients', null, { root: true }))
        },
        fetchProjectName({ rootGetters }, data) {
            return axiosInstance.get(`/clients/${data.clientId}/projects/${data.projectId}/project-name`, rootGetters["authModule/getUrlConfig"])
                .then((result) => result?.data)
        },
        updateProjectName({ rootGetters }, data) {
            return axiosInstance.put(`/clients/${data.clientId}/projects/${data.projectId}/project-name`, data.projectName, addPlainTextHeader(rootGetters["authModule/getUrlConfig"]))
        },
        deleteProject({ dispatch, rootGetters }, data) {
            return axiosInstance.delete(`/clients/${data.clientId}/projects/${data.projectId}`, rootGetters["authModule/getUrlConfig"])
                .then(() => dispatch('clientsModule/fetchNotArchivedClients', null, { root: true }))
        },
        toggleProjectActive({ dispatch, rootGetters }, data) {
            return axiosInstance.put(`/clients/${data.clientId}/projects/${data.projectId}/active`, null, rootGetters['authModule/getUrlConfig'])
                .then(() => dispatch('clientsModule/fetchNotArchivedClients', null, { root: true }))
        },
        changeClient({ rootGetters }, data) {
            return axiosInstance.put(`/clients/${data.clientId}/projects/${data.projectId}/change-client`, data.newClientId, addPlainTextHeader(rootGetters["authModule/getUrlConfig"]))
        },
        updateFunctionalId({ rootGetters }, data) {
            return axiosInstance.put(`/clients/${data.clientId}/projects/${data.projectId}/functional-id`, data.newId, addPlainTextHeader(rootGetters["authModule/getUrlConfig"]))
        },
        getFunctionalIdSuggestions({ rootGetters }, data) {
            const url = `/clients/${data.clientId}/projects/${data.seed}/gen-functional-id`;

            // Conditionally add the query parameter if it exists in `data`
            const config = {
                ...rootGetters["authModule/getUrlConfig"],
                params: data.existingId ? { existingId: data.existingId } : {}
            };

            return axiosInstance.get(url, config)
                .then(
                    result => {
                        return result?.data
                    }
                )
        },

    }
}