<template>
    <div class="background--white white-frame" :class="$vuetify.breakpoint.smAndUp ? 'ma-6 pa-6 rounded border' : 'pa-5'">
        <slot></slot>
    </div>
</template>
<script>
export default {
    name: "WhiteFrame"
}
</script>

<style scoped>
.white-frame {
    transition: all 0.5s ease;
    overflow: hidden; /* Ensure no overflow during resizing */
}
</style>