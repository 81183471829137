<template>
  <ClientDetailItemEditCard confirmation-message="Klant id werd succesvol gewijzigd" title="Bewerk klant id"
    update-store-action="clientsModule/updateFunctionalId"
    :update-store-action-payload="{ newId: newId, clientId: clientId }"
    :clientId="clientId" :promises.sync="promises">
    <template v-slot:form-fields>
      <v-row>
        <v-col cols="10" md="3">
          <FunctionalIdInput 
              v-model="newId"
              :suggestion="lastFetchedFunctionalIdSuggestion"
              label="Klant id" prefix="C-"
              :rules="functionalIdRules"
              required />
        </v-col>
      </v-row>
      
    </template>
  </ClientDetailItemEditCard>
</template>

<script>
import ClientDetailItemEditCard from "./ClientDetailItemEditCard.vue"
import routeNames from "@/router/RouteNames"
import { getRequiredRules, getStartsWithRules, getMaxStringSizeRules, getMinStringSizeRules } from "@/shared/utils/inputRulesUtils.js"
import FunctionalIdInput from "../../../components/shared/fields/FunctionalIdInput.vue"

export default {
  name: "ProjectDetailEditFunctionalId",
  props: {
    clientId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      routeNames,
      clientName: null,
      newId: null,
      promises: [
        this.$store.dispatch('clientsModule/fetchBasicClientDTO',  this.clientId )
          .then((result) => { this.clientName = result.clientName; this.newId = result.functionalId })
      ],
      lastFetchedFunctionalIdSuggestion: null,
      functionalIdRules: [
        ...getRequiredRules('Id van een klant moet ingevuld worden'),
        ...getStartsWithRules('C-', 'Id van een klant moet beginnen met C-'),
        ...getMinStringSizeRules(3, 'Id van een klant moet minimaal 1 teken bevatten'),
        ...getMaxStringSizeRules(6, 'Id van een klant mag maximaal 4 tekens bevatten')
      ],
    }
  },
  components: {
    ClientDetailItemEditCard,
    FunctionalIdInput,
  },
  methods: {
    getRequiredRules,
    getStartsWithRules,
    getMinStringSizeRules,
    getMaxStringSizeRules,
    fetchGeneratedFunctionalId() {
      if (this.clientName) {
        this.$store.dispatch("clientsModule/getFunctionalIdSuggestions", { clientId: this.clientId, seed: this.clientName, existingId: this.functionalId })
          .then(result => {
            this.lastFetchedFunctionalIdSuggestion = result
          })
      }
    },
  },
  watch: {
    clientName: {
      deep: true,
      immediate: true,
      handler() {
        if (this.clientName) {
          this.fetchGeneratedFunctionalId()
        }
      }
    },
  }
}
</script> 