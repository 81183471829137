<template>
  <div>
    <AvatarDetailEditForm :promises.sync="promises"
                          confirmationMessage="Logo werd succesvol gewijzigd"
                          title="Bewerk logo" :item-name="suborganizationName"
                          :detailViewRoute="{name: routeNames.ORGANIZATION_DETAIL, params: { organizationId: organizationId}, hash: '#' + suborganizationId}"
                          update-store-action-name="suborganizationModule/updateSuborganizationLogo" :update-store-action-payload="{organizationId, suborganizationId}"
                          avatar-property="file" :update-avatar-payload="{organizationId, suborganizationId}"
                          :id="suborganizationId" :hideSaveButton="true" retrieve-avatar="suborganizationModule/getSuborganizationLogo" :SASKey="SASKey">
    </AvatarDetailEditForm>
  </div>
</template>

<script>
import routeNames from "@/router/RouteNames";
import AvatarDetailEditForm from "@/components/shared/AvatarDetailEditForm";


export default {
  name: "SuborganizationDetailEditLogo",
  components: {
    AvatarDetailEditForm,
  },
  props: {
    organizationId: {
      type: String,
      required: true
    },
    suborganizationId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      routeNames: routeNames,
      suborganizationName: "",
      promises: [
        this.$store.dispatch('suborganizationModule/fetchSuborganizationLogo', { organizationId: this.organizationId, suborganizationId: this.suborganizationId }),
        this.$store.dispatch("fileExportModule/fetchSASKey")
            .then(result => this.SASKey = result),
        this.$store.dispatch('suborganizationModule/fetchSuborganizationName', {organizationId: this.organizationId, suborganizationId: this.suborganizationId})
          .then((result) => {this.suborganizationName = result})
      ],
      SASKey: null,
    }
  }
}
</script>