import axiosInstance, { addMultiPartFormDataHeader, addPlainTextHeader } from "@/store/axios.instance";

export default {
    namespaced: true,
    state() {
        return {
            suborganizationLogos: {}
        };
    },
    mutations: {
        setSuborganizationLogo(state, data) {
            const newSuborganizationLogos = { ...state.suborganizationLogos };
            newSuborganizationLogos[data.suborganizationId] = data.logo;
            state.suborganizationLogos = newSuborganizationLogos;
        }
    },
    actions: {
        fetchAllSuborganizations({ rootGetters }, organizationId) {
            return axiosInstance.get(
                `organization/${organizationId}/suborganizations`,
                rootGetters["authModule/getUrlConfig"]
            ).then(result => result?.data);
        },
        fetchSuborganization({ rootGetters }, { organizationId, suborganizationId }) {
            return axiosInstance.get(
                `organization/${organizationId}/suborganizations/${suborganizationId}`,
                rootGetters["authModule/getUrlConfig"]
            ).then(result => result?.data);
        },
        createSuborganization({ rootGetters }, { organizationId, createSuborganizationDTO }) {
            return axiosInstance.post(
                `organization/${organizationId}/suborganizations`,
                createSuborganizationDTO,
                rootGetters["authModule/getUrlConfig"]
            ).then(result => result?.data);
        },
        updateSuborganization({ rootGetters }, { organizationId, suborganizationId, updateSuborganizationDTO }) {
            return axiosInstance.put(
                `organization/${organizationId}/suborganizations/${suborganizationId}`,
                updateSuborganizationDTO,
                rootGetters["authModule/getUrlConfig"]
            ).then(result => result?.data);
        },
        deleteSuborganization({ rootGetters }, { organizationId, suborganizationId }) {
            return axiosInstance.delete(
                `organization/${organizationId}/suborganizations/${suborganizationId}`,
                rootGetters["authModule/getUrlConfig"]
            ).then(result => result?.data);
        },
        fetchSuborganizationLogo({ commit, rootGetters }, { organizationId, suborganizationId }) {
            return axiosInstance.get(
                `organization/${organizationId}/suborganizations/${suborganizationId}/logo`,
                rootGetters["authModule/getUrlConfig"]
            ).then(result => {
                if (result?.data) {
                    commit('setSuborganizationLogo', { logo: result?.data + '?timeStopCache=' + Date.now(), suborganizationId });
                    return result?.data + '?timeStopCache=' + Date.now();
                } else {
                    commit('setSuborganizationLogo', { logo: null, suborganizationId });
                    return null;
                }
            });
        },
        fetchSuborganizationLogos({ commit, rootGetters }, organizationId) {
            return axiosInstance.get(
                `organization/${organizationId}/suborganizations/logos`,
                rootGetters["authModule/getUrlConfig"]
            ).then(result => {
                if (result?.data) {
                    const logosWithCacheBuster = Object.keys(result.data).reduce((acc, key) => {
                        acc[key] = `${result.data[key]}?timeStopCache=${Date.now()}`;
                        return acc;
                    }, {});
                    commit('setSuborganizationLogos', logosWithCacheBuster);
                    return logosWithCacheBuster;
                } else {
                    return {};
                }
            });
        },
        updateSuborganizationLogo({ commit, rootGetters }, { organizationId, suborganizationId, file }) {
            const formData = new FormData();
            formData.append("file", file);

            return axiosInstance.post(
                `organization/${organizationId}/suborganizations/${suborganizationId}/logo`,
                formData,
                addMultiPartFormDataHeader(rootGetters["authModule/getUrlConfig"])
            ).then(result => {
                if (result?.data.startsWith("https")) {
                    commit('setSuborganizationLogo', { logo: result?.data + '?timeStopCache=' + Date.now(), suborganizationId });
                    return result?.data + '?timeStopCache=' + Date.now();
                } else {
                    commit('setSuborganizationLogo', { logo: null, suborganizationId });
                    return null;
                }
            });
        },
        fetchSuborganizationName({ rootGetters }, { organizationId, suborganizationId }) {
            return axiosInstance.get(
                `organization/${organizationId}/suborganizations/${suborganizationId}/name`,
                rootGetters["authModule/getUrlConfig"]
            ).then(result => result?.data);
        },
        updateSuborganizationName({ rootGetters }, { organizationId, suborganizationId, name }) {
            return axiosInstance.put(
                `organization/${organizationId}/suborganizations/${suborganizationId}/name`,
                name,
                addPlainTextHeader(rootGetters["authModule/getUrlConfig"])
            ).then(result => result?.data);
        }
    },
    getters: {
        getSuborganizationLogo: (state) => (suborganizationId) => {
            return state.suborganizationLogos[suborganizationId];
        }
    }
};
