import { compareDesc, isBefore, isWithinInterval } from "date-fns"

export function isLatestElementOfList(index, list){
    return index !== (list.length - 1)
}

export function sortListAlphabeticallyByParameter(list, parameter) {
    return list.sort((a, b) => {
        if (a[parameter].toLowerCase() < b[parameter].toLowerCase()) {
            return -1
        } else if (a[parameter].toLowerCase() > b[parameter].toLowerCase()) {
            return 1
        } else {
            return 0
        }
    })
}

export function onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
}
  
export function differenceLeft(a, b) {
    return a.filter(x => !b.includes(x));
}

export function getItemAt(list, startProperty, endProperty, date) {
    return list.find(item => isWithinInterval(date, {start: item[startProperty], end: item[endProperty]}))
}

export function getItemAtOrBeforeDate(list, startProperty, endProperty, date) {
    let activeItem = getItemAt(list, startProperty, endProperty, date)
    if (!activeItem) {
        activeItem = list.filter(item => isBefore(item[endProperty], date)).sort((a, b) => compareDesc(a[endProperty], b[endProperty]))[0]
    }
    return activeItem
}