<template>
  <v-card
    tile
    flat
  >
    <v-card-title id="subscription">
      <span class="pr-2">Abonnement:</span> <span>{{ applyDateFormat(activeSubscription?.startDate) }} - {{ applyDateFormat(activeSubscription?.endDate) }}</span>
    </v-card-title>
    <v-divider />
    <PromiseContainer :promises.sync="promises">
      <v-row class="mt-1">
        <v-col
          cols="12"
        >
          <p>
            <v-icon>mdi-account-check</v-icon>
            Aantal gebruikers: {{ activeSubscription.seatsUsed }}/{{ activeSubscription.totalSeats }}<span v-if="!isTrial && enableSubscriptionChanges"> — <span
              class="clickable"
              @click="requestExtraSeats=!requestExtraSeats"
            >Extra gebruikers toevoegen</span></span>
          </p>
          <p>
            Maandelijks kostprijs €{{ getMonthlyCost }}
          </p>
          <v-expand-transition>
            <extra-seats
              v-if="enableSubscriptionChanges && requestExtraSeats"
              v-model="requestExtraSeats"
              :active-subscription="activeSubscription"
            />
          </v-expand-transition>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="7"
        >
          <v-subheader>
            Modules
          </v-subheader>
          <v-list
            class="pa-0"
            shaped
            disabled
          >
            <v-list-item-group>
              <v-list-item
                v-for="module in getModules"
                :key="module"
              >
                <v-list-item-icon>
                  <v-icon>mdi-view-module</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <!-- Ignore rule here because v-text is in the Vuetify documentation -->
                  <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
                  <v-list-item-title v-text="module" />
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <v-expand-transition>
            <SubscriptionExtend
              v-if="enableSubscriptionExtensionRenewal && requestExtend"
              :subscription.sync="activeSubscription"
              class="mt-2"
            />
          </v-expand-transition>
        </v-col>
        <v-col
          cols="12"
          md="5"
        >
          <v-subheader>
            Geldigheid
          </v-subheader>
          <v-timeline dense>
            <v-timeline-item
              v-for="(item, index) in getValidity"
              :key="item.label"
              :color="item.color"
              :hide-dot="item.extendOption"
              :class="{ 'pb-0': index === getValidity.length - 1 }"
              small
            >
              <div>
                <span v-if="!item.extendOption"><strong>{{
                  item.label
                }}</strong> {{ item.date ? format(item.date, dateFormat) : null }}</span>
              </div>
            </v-timeline-item>
            <v-timeline-item
              v-if="enableSubscriptionExtensionRenewal"
              hide-dot
            >
              <span
                class="clickable"
                @click="requestExtensionOrTrialToPaid"
              >
                {{ `${isTrial ? 'Koop' : isExtension ? 'Verleng' : 'Vernieuw'} abonnement` }}
              </span>
            </v-timeline-item>
          </v-timeline>
        </v-col>
      </v-row>
    </PromiseContainer>
  </v-card>
</template>

<script>

import compareAsc from "date-fns/compareAsc";
import {translateModuleName} from "@/shared/utils/translateUtils";
import format from "date-fns/format";
import PromiseContainer from "@/components/shared/PromiseContainer.vue";
import {isAfter} from "date-fns";
import ExtraSeats from "@/components/settings/ExtraSeats";
import routeNames from "@/router/RouteNames";
import SubscriptionExtend from "@/components/settings/subscriptions/SubscriptionExtend.vue";
import { applyDateFormat } from "@/shared/utils/dateUtils"


export default {
    name: "SubscriptionInfo",
    components: {ExtraSeats, PromiseContainer, SubscriptionExtend},
    props: {
      subscriptionId: {
        type: String,
        required: false,
        default: null
      },
      enableSubscriptionChanges: {
        type: Boolean,
        required: false,
        default: false
      },
      enableSubscriptionExtensionRenewal: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data() {
        return {
            format: format,
            requestExtraSeats: false,
            requestExtend: false,
            amountOfMonths: 1,
            expectedPrice: 0.0,
            promises: [
                // re fetch active subscription info
                // whenever user extends a subscription, he is currently navigated to the settings view
                // ensure that the active subscription info get refreshed
                // TODO, create specific route view for this, only do this fetch action on this specific route view - BUILDBASE-818
                this.$store.dispatch('organizationModule/fetchSubscriptionStatus')
                    .then(data => {
                        this.isTrial = data.trial
                    }),

                // update date format
                // add 'kost prijs' to verleng/vernieuw abo
                this.$store.dispatch('organizationModule/fetchAddress')
                    .then(data => this.countryCode = data.countryCode),
            ],
            isTrial: null,
            countryCode: null,
            activeSubscription: {},
        }
    },
    computed: {
        getModules() {
            if (!this.activeSubscription?.modules) {
                return null
            }
            return this.activeSubscription.modules.map(module => translateModuleName(module))
        },
        getValidity() {
            return [
                {
                    label: 'Start datum',
                    date: this.activeSubscription.startDate ? new Date(this.activeSubscription.startDate) : null,
                    color: 'green',
                },
                {
                    label: 'Eind datum',
                    date: this.activeSubscription.endDate ? new Date(this.activeSubscription.endDate) : null,
                    color: 'green',
                },
                {
                    label: 'Vandaag',
                    date: new Date(),
                    color: 'grey',
                }
            ].sort((a, b) => compareAsc(a.date, b.date))
        },
        isExtension() {
            // check if the user is extending or renewing (= create new subscription as it is expired) his subscription
            return isAfter(new Date(this.activeSubscription.endDate), new Date())
        },
        getMonthlyCost() {
            return (this.activeSubscription.price * this.activeSubscription.totalSeats).toFixed(2)
        },
        scrollBottom() {
            return document.body.scrollHeight
        },
        dateFormat() {
            return this.$store.getters["parameterModule/getDateFormat"]
        }
    },
    watch: {
        subscriptionId: {
        immediate: true, 
        handler(newValue) {
            if (newValue) {
                this.fetchSubscriptionInfo(newValue)
            }
        }
        }
    },
    created() {
        // if returning from redirection
        if(this.$route.query.price) {
            this.organizationId = this.$route.query.organizationId
            const eventParams = {
                transaction_id: this.$route.query.organizationId,
                value: this.$route.query.price,
                currency: 'EUR',
                items: [
                    {
                    name: 'abonnement',
                    category: this.$route.query.source,
                    quantity: this.$route.query.amount,
                    price: this.$route.query.price
                    }
                ]
            };
            this.$gtag.event("purchase", eventParams);
        }
    },
    methods: {
      applyDateFormat,
        fetchSubscriptionInfo(subscriptionId) {
            this.promises.push(this.$store.dispatch('organizationModule/fetchSubscriptionInfo', subscriptionId)
                .then(data => {
                    this.activeSubscription = data
                    this.recalculatePrice()
                })
            )
        },
        requestExtensionOrTrialToPaid() {
            if (this.isTrial) {
                this.$router.push({name: routeNames.TRIAL_TO_PAID})
            } else {
                this.requestExtend = !this.requestExtend;
                this.$vuetify.goTo(this.scrollBottom)
            }
        },
        recalculatePrice() {
            if(!!this.amountOfMonths && this.amountOfMonths > 0) {
                this.promises.push(
                    this.$store.dispatch('paymentModule/calculatePriceForExtensionOrRenewal', {amountOfMonths: this.amountOfMonths, seats: this.activeSubscription?.totalSeats})
                    .then((success) => this.expectedPrice = success?.data)
                )
            }
        }
    }
}
</script>
