<template>
    <BasicInformationCard :title="suborganizationInformation.name"
        :editRouterLink="{ name: routeNames.SETTINGS_SUBORGANIZATION_DETAIL_EDIT_INFORMATION, params: { organizationId: suborganizationInformation.organizationId, suborganizationId: suborganizationInformation.id } }"
        :promises.sync="promises">
        <template v-slot:content>
            <v-container>
                <v-row>
                    <v-col cols="12" md="7">
                        <v-row>
                            <v-col cols="6">
                                <BasicInformationItem description="Adres" :value="suborganizationInformation.address">
                                    <template v-slot:value-display="slotProps">
                                        <p v-if="slotProps.value" class="subtitle-1 font-weight-medium black--text">
                                            <span class="notranslate">
                                                {{ slotProps.value.street }} {{ slotProps.value.number }}
                                                {{ slotProps.value.bus }}
                                            </span>
                                            <br />
                                            {{ slotProps.value.postalCode }}
                                            {{ slotProps.value.city }} {{ slotProps.value.region }}
                                            <br />
                                            <country-flag v-if="slotProps.value.countryCode"
                                                :country="slotProps.value.countryCode" size="small" rounded />
                                            {{ translateNationalitiy(slotProps.value.countryCode) }}
                                        </p>
                                    </template>
                                </BasicInformationItem>
                            </v-col>
                            <v-col cols="6">
                                <BasicInformationItem description="Btw nummer" :value="suborganizationInformation.vatNumber" noTranslate/>
                            </v-col>
                            <v-col cols="6">
                                <BasicInformationItem description="Bankrekening" :value="suborganizationInformation.bankAccountNumber" noTranslate/>
                            </v-col>
                            <v-col cols="6">
                                <BasicInformationItem description="Contact Email" :value="suborganizationInformation.contactEmail" noTranslate/>
                            </v-col>
                        </v-row>
                    </v-col>
                    <GoogleMapsFrame :mapsURL="getGoogleMapsUrl(suborganizationInformation)" 
                    unavailableMessage="Er is geen adres beschikbaar" :staySmallAboveMedium=true />
                </v-row>
            </v-container>
        </template>
    </BasicInformationCard>
</template>

<script>
import BasicInformationCard from "../shared/BasicInformationCard.vue"
import BasicInformationItem from "../shared/BasicInformationItem.vue"
import routeNames from "@/router/RouteNames"
import { translateNationalitiy } from "@/shared/utils/translateUtils"
import CountryFlag from "vue-country-flag"
import { applyDateFormat } from "@/shared/utils/dateUtils"
import { getGoogleMapsUrl } from "@/shared/utils/requestUtils"
import GoogleMapsFrame from "@/components/shared/GoogleMapsFrame.vue"

export default {
    name: "SuborganizationInformationComponent",
    props: {
        suborganizationInformation: {
            type: Object,
            required: true,
        },
    },
    components: {
        BasicInformationCard,
        BasicInformationItem,
        CountryFlag,
        GoogleMapsFrame,
    },
    data() {
        return {
            routeNames,
            promises: [],
        }
    },

    methods: {
        translateNationalitiy,
        applyDateFormat,
        getGoogleMapsUrl,
    },
}
</script>
