<template>
    <DetailItemEditCard v-bind="$attrs" v-on="$listeners" :item-name="itemName"
      :promises.sync="detailItemEditCardPromises" :formPromises.sync="computedPromises"
      :detailViewRouteLocation="{ name: routeNames.ORGANIZATION_DETAIL, params: { organizationId: organizationId } }">
      <template v-slot:form-fields>
        <slot name="form-fields" />
      </template>
      <template v-slot:buttons-left="slotProps">
        <slot name="buttons-left" v-bind="slotProps"/>
      </template>
      <template v-slot:save-button-content>
        <slot name="save-button-content" />
      </template>
    </DetailItemEditCard>
  </template>
    
  <script>
  import DetailItemEditCard from "@/components/shared/DetailItemEditCard.vue"
  import routeNames from "@/router/RouteNames";
  
  export default {
    name: "SuborganizationDetailItemEditCard",
    props: {
      organizationId: {
        type: String,
        required: true
      },
      suborganizationId: {
        type: String,
        required: false
      },
      promises: {
        default: () => []
      }
    },
    emits: ["update:promises"],
  
    data() {
      return {
        itemName: null,
        routeNames,
        detailItemEditCardPromises: [this.fetchPageTitle()]
      }
    },
    inheritAttrs: false,
    components: {
      DetailItemEditCard,
    },
    computed: {
      computedPromises: {
        get() {
          return this.promises
        },
        set(newValue) {
          this.$emit("update:promises", newValue)
        }
      }
    },
    methods: {
      fetchPageTitle() {
        if (this.suborganizationId) {
          return this.$store.dispatch('suborganizationModule/fetchSuborganizationName', {organizationId: this.organizationId, suborganizationId: this.suborganizationId})
            .then((result) => this.itemName = result)
        } else {
          return this.$store.dispatch('organizationModule/fetchOrganizationName', this.organizationId)
            .then(result => this.itemName = result?.name)
        }
      }
    }
  }
  </script>