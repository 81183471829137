<template>
  <v-row>
    <v-col>
      <DatePicker v-model="getDateAsString" :label="label + ' Dag'" required :min="getMin" :disabled="disabledDate || disabled" />
    </v-col>
    <v-col>
      <TimePicker v-model="getDate" :label="label  + ' Uur'" required :min="min" :disabled="disabledTime || disabled" />
    </v-col>
  </v-row>
</template>

<script>
import TimePicker from './TimePicker.vue'
import DatePicker from './DatePicker.vue'


export default {
  name: "TimeAndDatePicker",
  emits: ['update:value'],
  props: {
    value: Date,
    label: String,
    min: {
      type: Date
    },
    disabled: Boolean,
    disabledTime: Boolean,
    disabledDate: Boolean
  },
  components: {
    TimePicker,
    DatePicker
  },
  methods: {

  },
  computed: {
    getDate: {
      get() {
        return this.value // Pass the raw Date object to time picker
      },
      set(newDate) {
        this.$emit('update:value', newDate) // BUILDBASE-1104 refactor to only use update:value
        this.$emit('input', newDate)
      },
    },
    getDateAsString: {
      get() {
        // Format the Date object to "yyyy-mm-dd" to be compatible with date picker
        if (!this.value) {
          return null
        }

        const year = this.value.getFullYear()
        const month = String(this.value.getMonth() + 1).padStart(2, '0')
        const day = String(this.value.getDate()).padStart(2, '0')
        return `${year}-${month}-${day}`
      },
      set(newDateString) {
        // Parse the date picker feedback "yyyy-mm-dd" string back to the Date object
        const [year, month, day] = newDateString.split('-').map(Number)
        const updatedDate = new Date(this.value)
        updatedDate.setFullYear(year, month - 1, day)
        this.$emit('update:value', updatedDate) // BUILDBASE-1104 refactor to only use update:value
        this.$emit('input', updatedDate)
      },
    },
    getMin() {
        // For the datepicker, subtract a day from the minimum, to allow start and end date to be the same 
        if (!this.min) {
          return null
        }
        const minDate = new Date(this.min)
        minDate.setDate(minDate.getDate() - 1)
        return minDate
      },
  }
}

</script>