import axiosInstance, {addPlainTextHeader} from "@/store/axios.instance";

export default {
    namespaced: true,
    state() {
        return {
            user: null,
            basicUser: {
                id: null,
                firstName: null,
                lastName: null,
                jobTitle: null,
                statute: null,
                active: null,
                teamNamesById: null
            },
            userName: {
                firstName: null,
                lastName: null
            },
            statute: null,
            jobTitle: null,
            startTimePerDay: null,
            userPersonalInformation: {
                birthDate: null,
                gender: null,
                bankAccountNumber: null,
                nationalities: null,
                civilStatus: null,
                dependents: null,
                birthPlace: null,
                taxIdentificationNumber: null
            },
            userContactInformation: {
                address: {
                    city: null,
                    postalCode: null,
                    countryCode: null,
                    region: null,
                    street: null,
                    number: null,
                    bus: null
                },
                phoneNumber: null,
                email: null,
                personalEmail: null,
                contacts: null
            },
            userWorkSchedule : {
                name: null,
                breakLength: null,
                hoursPerDay: null
            },
            users: null,
            statutes: null,
            hoursWorkDay: null,
            roles: null,
            userRoles: null,
            userAvatars: {},
        }
    },
    mutations: {
        setStatutes(state, statutes) {
            state.statutes = statutes
        },
        setUsers(state, users) {
            state.users = users.forEach((user => {if(user.avatar){
                user.avatar +=  '?timeStopCache='+ Date.now()}
            else{
                user.avatar = null
            }}))
        },
        setUser(state, user) {
            state.user = user
        },
        setBasicUser(state, basicUser) {
            state.basicUser = basicUser
        },
        setBasicUserActive(state, active) {
            state.basicUser.active = active
        },
        setUserName(state, userName) {
            state.userName = userName
        },
        setStatute(state, statute) {
            state.statute = statute
        },
        setJobTitle(state, jobTitle) {
            state.jobTitle = jobTitle
        },
        setStartTimePerDay(state, startTimePerDay) {
            state.startTimePerDay = startTimePerDay
        },
        setUserPersonalInformation(state, userPersonalInformation) {
            state.userPersonalInformation = userPersonalInformation
        },
        setUserContactInformation(state, userContactInformation) {
            state.userContactInformation = userContactInformation
        },
        setUserRoles(state, userRoles) {
            state.userRoles = userRoles
        },
        setRoles(state, roles) {
            state.roles = roles
        },
        insertUser(state, newUser) {
            const users = state.users
            if(!users){
                state.users = [newUser]
                return
            }
            const indexOfExistingUser = users.findIndex(user => user.id === newUser.id)
            if (indexOfExistingUser === -1) {
                users.push(newUser)
            } else {
                users.splice(indexOfExistingUser, 1, newUser)
            }
        },
        setUserWorkSchedule(state, userWorkSchedule) {
            state.userWorkSchedule = userWorkSchedule
        },
        setUserAvatar(state,data) {
            var newUserAvatars = {...state.userAvatars}
            newUserAvatars[data.userId] = data.avatar
            state.userAvatars = newUserAvatars
            }
    },
    actions: {
        fetchStatutes({commit, rootGetters}) {
            return axiosInstance.get('/config/statutes', rootGetters["authModule/getUrlConfig"])
                .then((result) => {
                    commit('setStatutes', result?.data)
                    return result?.data
                })
        },
        fetchUsers({commit, rootGetters}) {
            return axiosInstance.get('/users', rootGetters['authModule/getUrlConfig'])
                .then(result => {commit('setUsers', result?.data)
                return result?.data})
        },
        getActiveUsersFromOrganization({rootGetters}) {
            return axiosInstance.get('/users/active', rootGetters['authModule/getUrlConfig'])
                .then(result => {return result?.data})
        },
        getUsersOfTeamLeader({commit, rootGetters}, userId ){
            return axiosInstance.get('/teams/' + userId + '/users-of-team-leader', rootGetters['authModule/getUrlConfig'])
                .then(result => {commit('setUsers', result?.data)
                    return result?.data})
        },
        createUser({commit, rootGetters}, user) {
            return axiosInstance.post('/users', user, rootGetters['authModule/getUrlConfig'])
                .then(result => {
                    commit('insertUser', result?.data)
                    return result?.data
                })
                .catch(error => Promise.reject(error))
        },
        async updateUser({commit, rootGetters}, userToUpdate) {
            await axiosInstance.put('/users', userToUpdate, rootGetters['authModule/getUrlConfig'])
                .then(result => commit('insertUser', result?.data))
                .catch(error => Promise.reject(error))
        },
        fetchUsersFromOrganizationNotInTeam({commit, rootGetters}, team) {
            return axiosInstance.get('/users/organization/' + team.id, rootGetters['authModule/getUrlConfig'])
                .then(result => {
                    commit('setUsers', result?.data)
                    return result?.data
                })
        },
        fetchUser({commit, rootGetters}, userId) {
            return axiosInstance.get('/users/' + userId, rootGetters['authModule/getUrlConfig'])
                .then(result => {
                    commit('setUser', result?.data)
                    return result?.data
                })
        },
        fetchBasicUser({commit, rootGetters}, userId) {
            return axiosInstance.get('/users/' + userId + '/basic', rootGetters['authModule/getUrlConfig'])
                .then(result => commit('setBasicUser', result?.data))
        },
        fetchUserName({commit, rootGetters}, userId) {
            return axiosInstance.get('/users/' + userId + '/user-name', rootGetters['authModule/getUrlConfig'])
                .then(result => {
                    commit('setUserName', result?.data)
                    return result?.data
                })
        },
        fetchStatute({commit, rootGetters}, userId) {
            return axiosInstance.get('/users/' + userId + '/statute', rootGetters['authModule/getUrlConfig'])
                .then(result => {
                    commit('setStatute', result?.data)
                    return result?.data
                })
        },
        updateStatute({commit, rootGetters}, data) {
            return axiosInstance.put('/users/' + data.userId + '/statute', data.statuteDTO, rootGetters["authModule/getUrlConfig"])
                .then(result => commit('setStatute', result?.data))
        },
        fetchJobTitle({commit, rootGetters}, userId) {
            return axiosInstance.get('/users/' + userId + '/job-title', rootGetters['authModule/getUrlConfig'])
                .then(result => {
                    commit('setJobTitle', result?.data)
                    return result?.data
                })
        },
        updateJobTitle({commit, rootGetters}, data) {
            return axiosInstance.put('/users/' + data.userId + '/job-title',data.jobTitleDTO, rootGetters["authModule/getUrlConfig"])
                .then(result => commit('setJobTitle', result?.data))
        },
        fetchStartTimePerDay({commit, rootGetters}, userId) {
            return axiosInstance.get('/users/' + userId + '/start-time-per-day', rootGetters['authModule/getUrlConfig'])
                .then(result => {
                    commit('setStartTimePerDay', result?.data)
                    return result?.data
                })
        },
        updateStartTimePerDay({commit, rootGetters}, data) {
            return axiosInstance.put('/users/' + data.userId + '/start-time-per-day', data.weekdaysWithTimeMapDTO, rootGetters["authModule/getUrlConfig"])
                .then(result => {
                    commit('setStartTimePerDay', result?.data)
                    return result?.data
                })
        },
        updateUserName({commit, rootGetters}, data) {
            return axiosInstance.put('/users/' + data.userId + '/user-name', data.userName, rootGetters['authModule/getUrlConfig'])
                .then(result => commit('setUserName', result?.data))
        },
        fetchUserPersonalInformation({commit, rootGetters}, userId) {
            return axiosInstance.get('/users/' + userId + '/personal-information', rootGetters['authModule/getUrlConfig'])
                .then(result => { 
                    commit('setUserPersonalInformation', result?.data)
                    return result?.data
                })
        },
        updateUserPersonalInformation({commit, rootGetters}, userToUpdate) {
            return axiosInstance.put('/users/' + userToUpdate.userId + '/personal-information', userToUpdate.personalInformation, rootGetters['authModule/getUrlConfig'])
                .then(result => commit('setUserPersonalInformation', result?.data))
        },
        fetchUserContactInformation({commit, rootGetters}, userId) {
            return axiosInstance.get('/users/' + userId + '/contact-information', rootGetters['authModule/getUrlConfig'])
                .then(result => { 
                    commit('setUserContactInformation', result?.data)
                    return result?.data
                })
        },
        updateUserContactInformation({commit, rootGetters}, userToUpdate) {
            return axiosInstance.put('/users/' + userToUpdate.userId + '/contact-information', userToUpdate.contactInformation, rootGetters['authModule/getUrlConfig'])
                .then(result => commit('setUserContactInformation', result?.data))
        },
        toggleUserActive({commit, rootGetters}, userId) {
            return axiosInstance.put('/users/' + userId + '/active', null, rootGetters['authModule/getUrlConfig'])
                .then(result => {
                    commit('setBasicUserActive', result?.data)
                    return result?.data
                })
        },
        updateBasicUser({commit, rootGetters}, userToUpdate) {
            return axiosInstance.put('/users/' + userToUpdate.id + '/basic', userToUpdate, rootGetters['authModule/getUrlConfig'])
                .then(result => commit('setBasicUser', result))
        },
        fetchRoles({commit, rootGetters}) {
            return axiosInstance.get('/users/roles', rootGetters['authModule/getUrlConfig'])
                .then(result => {
                    let roles = result?.data
                    if(!rootGetters['parameterModule/getEnableTeamsOption']){
                        roles = result?.data.filter((role)=> role.roleName !== 'ROLE_LEADER')
                    }
                    commit('setRoles', roles)
                    return roles
                })
        },
        fetchUserRoles({commit, rootGetters}, userId) {
            return axiosInstance.get('/users/' + userId + '/roles', rootGetters['authModule/getUrlConfig'])
                .then(result => {
                    commit('setUserRoles', result?.data)
                    return result?.data 
                })
        },
        updateUserRoles({commit, rootGetters}, payload) {
            return axiosInstance.put('/users/' + payload.userId + "/roles", payload.roles, rootGetters['authModule/getUrlConfig'])
                .then(result => commit('setUser', result?.data))
        },
        isValidEmail({rootGetters}, email) {
            return axiosInstance.get('/users/validate/email/' + email, rootGetters['authModule/getUrlConfig'])
        },
        fetchUserWorkSchedule({commit, rootGetters}, userId) {
            return axiosInstance.get('/users/' + userId + "/work-schedule", rootGetters['authModule/getUrlConfig'])
            .then(result => {
                commit('setUserWorkSchedule', result?.data)
                return result?.data 
            })
        },
        updateUserWorkSchedule({rootGetters}, payload) {
            return axiosInstance.put('/users/' + payload.userId + "/work-schedule", payload.workScheduleId, addPlainTextHeader(rootGetters['authModule/getUrlConfig']))
            .then(result => {
                return result?.data
            })
        },
        updateUserWorkScheduleAndStartTimePerDay({rootGetters}, payload) {
            //This endpoint exists because when updating the workschedule and the starttime at the same time, they would often overwrite eachothers changes
            return axiosInstance.put('/users/' + payload.userId + "/work-schedule-and-start-time-per-day", payload.userWorkscheduleAndStartTimePerDayDTO, rootGetters['authModule/getUrlConfig'])
            .then(result => {
                return result?.data
            })
        },
        fetchUserAvatar({commit,rootGetters}, userId){
            return axiosInstance.get('/users/'+ userId + '/avatar',rootGetters['authModule/getUrlConfig'])
                .then(result => {
                    if(result?.data){
                        commit ('setUserAvatar', {avatar: result?.data + '?timeStopCache='+ Date.now(),userId: userId})
                        return result?.data + '?timeStopCache='+ Date.now()
                    } else {
                        commit ('setUserAvatar', {avatar: null, userId:userId})
                        return null
                    }
                })
        },
        updateUserAvatar({commit,rootGetters}, data){
            let avatar = new FormData();
            avatar.append('file',data.image);
            return axiosInstance.post('/users/' + data.id + '/avatar', avatar , rootGetters['authModule/getUrlConfig'])
                .then((result) =>{
                    if(result?.data.startsWith("https")){
                        commit ('setUserAvatar', {avatar: result?.data + '?timeStopCache='+ Date.now(),userId: data.id})
                        return result?.data + '?timeStopCache='+ Date.now()
                    } else {
                        commit ('setUserAvatar', {avatar: null, userId:data.id})
                        return null
                    }})


        },
        removeUserAvatar({commit,rootGetters}, data){
            return axiosInstance.delete('/users/' + data.userId + '/avatar', rootGetters['authModule/getUrlConfig'])
                .then(() => {
                    commit('setUserAvatar', {avatar: null, userId:data.userId})
                    return null
                })
        }

    },
    getters: {
        countActiveUsers(state) {
            if (state.users)
                return state.users.filter(user => user.active).length
            else
                return 0
        },
        getStatutes(state) {
            return state.statutes
        },
        getUserAvatar: (state) => (userId) => {
            return state.userAvatars[userId]
        }
    },
}