<template>
    <div>
        <BasicDetailHeaderComponent :promises.sync="promises"
            :title="suborganizationName" retrieveAvatar="suborganizationModule/getSuborganizationLogo" :id="suborganizationId"
            :avatarEditRoute="{ name: routeNames.SETTINGS_SUBORGANIZATION_DETAIL_EDIT_LOGO, params: { organizationId, suborganizationId } }" default-avatar="default-organization-avatar.svg"
            :titleEditRoute="{ name: routeNames.SETTINGS_SUBORGANIZATION_DETAIL_EDIT_NAME, params: { organizationId, suborganizationId } }" class="notranslate"
            menu-button-text="Acties">
            <template v-slot:content>
                <v-col cols="12">
                    Suborganisatie id: {{ suborganizationId }}
                    <CopyText :textToCopy="suborganizationId" displayCopyIcon />
                </v-col>
            </template>
            <template #status-chip>
                <div></div>
            </template>
            <template v-slot:actions>
                <v-list-item link @click="onDeleteSuborganizationClick">
                    <v-list-item-icon><v-icon>mdi-delete</v-icon></v-list-item-icon>
                    <v-list-item-title>Verwijder</v-list-item-title>
                </v-list-item>
            </template>
        </BasicDetailHeaderComponent>
        <ConfirmationDialog :title="getDeleteDialogTitle" :description="getDeleteDialogDescription"
            v-model="deleteDialog" :onConfirm="deleteProject" />
    </div>
</template>

<script>
import BasicDetailHeaderComponent from "../shared/BasicDetailHeaderComponent.vue"
import routeNames from "@/router/RouteNames"
import CopyText from "@/components/shared/actions/CopyText"
import ConfirmationDialog from "@/components/shared/ConfirmationDialog.vue";

export default {
    name: "SuborganizationDetailHeaderComponent",
    components: {
        CopyText,
        BasicDetailHeaderComponent,
        ConfirmationDialog,
    },
    props: {
        organizationId: {
            type: String,
            required: true,
        },
        suborganizationId: {
            type: String,
            required: true,
        },
        suborganizationName: {
            type: String,
            required: true
        },
        onChange: {
            type: Function,
            required: false
        }
    },
    data() {
        return {
            promises: [this.$store.dispatch('suborganizationModule/fetchSuborganizationLogo', { organizationId: this.organizationId, suborganizationId: this.suborganizationId })],
            routeNames,
            SASKey: null,
            deleteDialog: false
        }
    },
    computed: {
        getDeleteDialogTitle() {
            return `Suborganisatie ${this.suborganizationName} verwijderen`
        },

        getDeleteDialogDescription() {
            return `Bent u zeker dat u suborganisatie ${this.suborganizationName} wilt verwijderen?`
        },
    },
    methods: {        
        onDeleteSuborganizationClick() {
            this.deleteDialog = true
        },
        deleteProject() {
            this.promises.push(
                this.$store.dispatch("suborganizationModule/deleteSuborganization", { organizationId: this.organizationId, suborganizationId: this.suborganizationId })
                    .then(() => {
                        if (this.onChange) {
                            this.onChange()
                        }
                    })
            )
        }
    }
}
</script>
