<template>
  <DetailItemEditCard :item-name="isClientIdPresent ? 'klanten' : 'projecten'" :promises.sync="promises"
    title="Nieuwe project aanmaken"
    :detailViewRouteLocation="isClientIdPresent ? { name: RouteNames.CLIENT_OVERVIEW } : { name: RouteNames.PROJECT_OVERVIEW }"
    confirmationMessage="Project werdt succesvol aangemaakt" update-store-action="projectsModule/createProject"
    :update-store-action-payload="newProject" :success-callback="projectCreatedSuccessCallback">
    <template v-slot:form-fields>
      <v-row>
        <v-col cols="12" md="4">
          <v-autocomplete :items="clients" type="text" v-model="newProject.clientId" label="Klant" required
            :rules="clientRules" item-text="clientName" item-value="id" tabindex="1" :disabled="isClientIdPresent"
            @blur="fetchGeneratedFunctionalId()">
            <template v-slot:item="slotProps">
              <v-list-item-content class="notranslate" v-text="slotProps.item.clientName"></v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field v-model="newProject.projectName" persistent-placeholder label="Project naam" type="text"
            :rules="projectNameRules" required @blur="fetchGeneratedFunctionalId()" />
        </v-col>
        <v-col cols="10" md="3">
          <FunctionalIdInput 
            v-model="newProject.functionalId"
            :suggestion="lastFetchedFunctionalIdSuggestion"
            label="Project id" prefix="P-"
            required
            :rules="functionalIdRules"/>
        </v-col>
        
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <DatePicker v-model="newProject.startDate" label="Start datum" required />
        </v-col>
        <v-col cols="12" md="6">
          <DatePicker v-model="newProject.endDate" label="Eind datum"
            :min="newProject.startDate ? new Date(newProject.startDate) : null" :default-now="false" />
        </v-col>
      </v-row>
    </template>
  </DetailItemEditCard>
</template>

<script>
import DetailItemEditCard from "@/components/shared/DetailItemEditCard.vue";
import DatePicker from "@/components/shared/fields/DatePicker.vue";
import RouteNames from "@/router/RouteNames";
import { getRequiredRules, getStartsWithRules, getMinStringSizeRules, getMaxStringSizeRules, } from "@/shared/utils/inputRulesUtils.js"
import FunctionalIdInput from "../../../components/shared/fields/FunctionalIdInput.vue"

export default {
  name: "ProjectCreate",
  components: {
    DetailItemEditCard,
    DatePicker,
    FunctionalIdInput,
  },
  props: {
    clientId: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      RouteNames,
      promises: [this.fetchClients()],
      clients: [],
      newProject: {
        clientId: this.clientId,
        active: true,
        functionalId : null
      },
      projectNameRules: [
        v => !!v || 'Project naam moet ingevuld worden',
      ],
      clientRules: [
        v => !!v || 'Er moet een klant geselecteerd worden',
      ],
      lastFetchedFunctionalIdSuggestion: null,
      functionalIdRules: [
        ...getRequiredRules('Id van het project moet ingevuld worden'),
        ...getStartsWithRules('P-', 'Id van een project moet beginnen met P-'),
        ...getMinStringSizeRules(3, 'Id van een project moet minimaal 1 teken bevatten'),
        ...getMaxStringSizeRules(6, 'Id van een project mag maximaal 4 tekens bevatten')
      ],
      
    }
  },
  methods: {
    fetchClients() {
      return this.$store.dispatch("clientsModule/fetchNotArchivedClients")
        .then(result => {
          this.clients = result
        })
    },
    projectCreatedSuccessCallback(createdProjectDTO) {
      this.$router.push({ name: RouteNames.PROJECT_DETAIL, params: { clientId: createdProjectDTO.clientId, projectId: createdProjectDTO.id } })
    },
    getRequiredRules,
    getStartsWithRules,
    getMinStringSizeRules,
    getMaxStringSizeRules,
    fetchGeneratedFunctionalId() {
      if (this.newProject.projectName && this.newProject.clientId) {
        this.$store.dispatch("projectsModule/getFunctionalIdSuggestions", { clientId: this.newProject.clientId, seed: this.newProject.projectName})
          .then(result => {
            this.newProject.functionalId = result
            this.lastFetchedFunctionalIdSuggestion = result
          })
      }
    },
  },
  computed: {
    isClientIdPresent() {
      return this.clientId != null
    },
  }
}
</script>