<template>
    <v-timeline dense class="d-flex flex-column flex-grow-1 justify-space-around pt-0">
        <TransitionGroup name="timeline" class="d-flex flex-column flex-grow-1 justify-space-around">
            <v-timeline-item v-for="index in emptyItemsToFillBefore" v-bind:key="'before' + index" class="flex-grow-0" hide-dot/>
            <v-timeline-item v-for="(timelineItem, index) in slicedTimeLineItems" v-bind:key="propertyToBind ? timelineItem[propertyToBind] : index" class="clickable flex-grow-0"
                @click.native="onTimelineItemClick(timelineItem)" @keydown.native.enter="onTimelineItemClick(timelineItem)" role="button" tabindex="0"
                :color="timelineItem.active ? 'green' : 'grey'" small>
                <slot name="timelineItem" v-bind="{timelineItem, selected: propertyToBind ? timelineItem[propertyToBind] == modelValue : timelineItem == modelValue}">
                    {{ timelineItem }}
                </slot>
            </v-timeline-item>
            <v-timeline-item v-for="index in emptyItemsToFillAfter" v-bind:key="'after' + index" class="flex-grow-0" hide-dot/>
        </TransitionGroup>
    </v-timeline>
</template>

<script>

export default {
    name: "CarouselTimeline",
    model: {        
        prop: 'value',
        event: 'update:value'
    },
    props: {
        timelineItems: {
            type: Array,
            required: false,
            default: () => []
        },
        propertyToBind: {
            type: String,
            required: false,
            default: null
        },
        value: {
            required: false,
            default: null,
        },
        items: {
            type: Array
        },
        itemsToDisplayBeforeSelected: {
            type: Number,
            required: false,
            default: 1
        },
        itemsToDisplayAfterSelected: {
            type: Number,
            required: false,
            default: 1
        }
    },
    emits: ['update:value'],
    methods: {
        onTimelineItemClick(timelineItem) {
            if (this.propertyToBind) {
                this.modelValue = timelineItem[this.propertyToBind]
            } else {
                this.modelValue = timelineItem
            }
        },
        compareToSelectedItem(otherItem) {
            if (this.propertyToBind) {
                return otherItem[this.propertyToBind] == this.modelValue
            } else {
                return otherItem == this.modelValue
            }
        }
    },
    computed: {
        modelValue: {
            get() {
                return this.value
            },
            set(newValue) {
                this.$emit('update:value', newValue)
            }
        },
        selectedItemIndex() {
            return this.timelineItems.findIndex(item => this.compareToSelectedItem(item))
        },
        minIndexUnclamped() {
            return this.selectedItemIndex - this.itemsToDisplayBeforeSelected
        },
        maxIndexUnclamped() {
            return this.selectedItemIndex + this.itemsToDisplayAfterSelected + 1
        },
        timeLineItemsLength() {
            return this.timelineItems?.length
        },
        slicedTimeLineItems() {
            if (this.selectedItemIndex == - 1) {
                return []
            }

            return this.timelineItems?.slice(Math.max(0, this.minIndexUnclamped), Math.min(this.maxIndexUnclamped, this.timeLineItemsLength))
        },
        emptyItemsToFillBefore() {
            return Math.max(0, -this.minIndexUnclamped)
        },
        emptyItemsToFillAfter() {
            return Math.max(0, this.maxIndexUnclamped - this.timeLineItemsLength)
        }
    }
};

</script>

<style>
.timeline-move,
.timeline-enter-active {
    transition: all 0.5s ease;
}

.timeline-leave-active {
    transition: all 0s ease;
}

.timeline-enter-from,
.timeline-leave-to {
    opacity: 0;
}

.timeline-leave-active {
  position: absolute;
}
</style>