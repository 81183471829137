import axiosInstance, { addPlainTextHeader } from "@/store/axios.instance";

export default {
    namespaced: true,
    state() {
        return {
        }
    },
    actions: {
        fetchInvoices({rootGetters}) {
            return axiosInstance.get('invoices', rootGetters["authModule/getUrlConfig"])
                .then(result => result?.data)
        },
        fetchInvoiceInformation({rootGetters}, payload) {
            return axiosInstance.get(`invoices/${payload.invoiceId}`, rootGetters["authModule/getUrlConfig"])
                .then(result => result?.data)
        },
        fetchInvoiceName({rootGetters}, payload) {
            return axiosInstance.get(`invoices/${payload.invoiceId}/name`, rootGetters["authModule/getUrlConfig"])
                .then(result => result?.data)
        },
        createInvoice({rootGetters}, invoiceDTO) {
            return axiosInstance.post(`invoices`, invoiceDTO, rootGetters["authModule/getUrlConfig"])
                .then(result => result?.data)
        },
        fetchCalculatedInvoiceLine({rootGetters}, parameters) {
            return axiosInstance.get('invoices/calculate-invoice-line', {...rootGetters["authModule/getUrlConfig"], params: parameters})
                .then(result => result?.data)
        },
        deleteInvoice({rootGetters}, invoiceId) {
            return axiosInstance.delete('/invoices/' + invoiceId, rootGetters["authModule/getUrlConfig"])
                .then(result => result?.data)
        },
        togglePaid({rootGetters}, invoiceId) {
            return axiosInstance.put(`invoices/${invoiceId}/toggle-paid`, null, rootGetters["authModule/getUrlConfig"])
        },
        fetchDraftInvoice({rootGetters}, invoiceId) {
            return axiosInstance.get(`invoices/${invoiceId}/draft`, rootGetters["authModule/getUrlConfig"])
                .then(result => result?.data)
        },
        updateInvoice({rootGetters}, payload) {
            return axiosInstance.put(`invoices/${payload.invoiceId}`, payload.invoiceDTO, rootGetters["authModule/getUrlConfig"])
                .then(result => result?.data)
        },
        fetchCreditNote({rootGetters}, invoiceId) {
            return axiosInstance.get(`invoices/${invoiceId}/credit`, rootGetters["authModule/getUrlConfig"])
                .then(result => result?.data)
        },
        fetchInvoiceDuplicate({rootGetters}, invoiceId) {
            return axiosInstance.get(`invoices/${invoiceId}/duplicate`, rootGetters["authModule/getUrlConfig"])
                .then(result => result?.data)
        },
        fetchNextNumbers({rootGetters}) {
            return axiosInstance.get('invoices/next-numbers', rootGetters["authModule/getUrlConfig"])
                .then(result => result?.data)
        },
        sendInvoice({rootGetters}, data) {
            return axiosInstance.post(`invoices/${data.invoiceId}/send`, data.emailAddress, addPlainTextHeader(rootGetters["authModule/getUrlConfig"]))
                .then(result => result?.data)
        },
    }
}