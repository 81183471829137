<template>
<DetailItemEditCard item-name="klanten" :promises.sync="detailItemEditCardPromises" title="Nieuwe klant aanmaken"
    :detailViewRouteLocation="{name: RouteNames.CLIENT_OVERVIEW}" confirmationMessage="Klant werdt succesvol aangemaakt" update-store-action="clientsModule/createClient"
     :update-store-action-payload="newClient" :success-callback="clientCreatedSuccessCallback">
      <template v-slot:form-fields>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field v-model="newClient.clientName" persistent-placeholder label="Klant naam" type="text" :rules="clientNameRules" required @blur="fetchGeneratedFunctionalId()"/>
            </v-col>
            <v-col cols="12" md="6">
              <FunctionalIdInput 
                v-model="newClient.functionalId"
                :suggestion="lastFetchedFunctionalIdSuggestion"
                label="Klant id" prefix="C-"
                required
                :rules="functionalIdRules"/>
            </v-col>
            <v-col cols="12" md="6">
              <DatePicker v-model="newClient.startDate" label="Start datum" required/>
            </v-col>
            <v-col cols="12" md="6">
              <DatePicker v-model="newClient.endDate" label="Eind datum" :min="newClient.startDate ? new Date(newClient.startDate) : null" :default-now="false"/>
            </v-col>
          </v-row>
      </template>
    </DetailItemEditCard>
</template>

<script>
import DetailItemEditCard from "@/components/shared/DetailItemEditCard.vue"
import DatePicker from "@/components/shared/fields/DatePicker.vue"
import RouteNames from "@/router/RouteNames"
import { getRequiredRules, getStartsWithRules, getMaxStringSizeRules , getMinStringSizeRules } from "@/shared/utils/inputRulesUtils.js"
import FunctionalIdInput from "../../../components/shared/fields/FunctionalIdInput.vue"

export default {
    name: "ClientCreate",
    components: {
        DetailItemEditCard,
        DatePicker,
        FunctionalIdInput,
    },
    data() {
        return {
          RouteNames,
          newClient: {
            functionalId: null,
          },
          detailItemEditCardPromises: [],
          lastFetchedFunctionalIdSuggestion: null,
          clientNameRules: [
            v => !!v || 'Klant naam moet ingevuld worden',
          ],
          functionalIdRules: [
            ...getRequiredRules('Id van een klant moet ingevuld worden'),
            ...getStartsWithRules('C-', 'Id van een klant moet beginnen met C-'),
            ...getMinStringSizeRules(3, 'Id van een klant moet minimaal 1 teken bevatten'),
            ...getMaxStringSizeRules(6, 'Id van een klant mag maximaal 4 tekens bevatten')
          ],
        }
    },
    methods: {
      getRequiredRules,
      getStartsWithRules,
      getMinStringSizeRules,
      getMaxStringSizeRules,
      clientCreatedSuccessCallback(clientId) {
        this.$router.push({name: RouteNames.CLIENT_DETAIL, params: {clientId: clientId}})
      },
      fetchGeneratedFunctionalId() {
        if (this.newClient.clientName) {
          this.$store.dispatch("clientsModule/getFunctionalIdSuggestions", { seed: this.newClient.clientName })
          .then(result => {
            this.newClient.functionalId = result
            this.lastFetchedFunctionalIdSuggestion = result
          })
        }
      },
    },
}
</script>