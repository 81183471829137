<template>
  <div>
    <UserDetailItemEditCard confirmation-message="Persoonlijke informatie werd succesvol gewijzigd"
                        title="Bewerk persoonlijke informatie"
                        :detailViewRouteLocation="{name: routeNames.USER_DETAIL, params: { userId: userId}}"
                        update-store-action="usersModule/updateUserPersonalInformation"
                        :update-store-action-payload="{userId: this.userId, personalInformation: this.userPersonalInformation}"
                        :userId="userId" :promises.sync="promises">
      <template v-slot:form-fields>
        <v-row>
          <v-col cols="12" sm="6">
              <DatePicker label="Geboortedatum" v-model="userPersonalInformation.birthDate" :default-now="false"/>
          </v-col>
          <v-col cols="12" sm="6">
            <CountryCodeSelect class="default-field-height" persistent-placeholder label="Nationaliteit" v-model="userPersonalInformation.nationalities" multiple small-chips deletable-chips clearable/>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field persistent-placeholder label="Geboorteplaats" type="text" v-model="userPersonalInformation.birthPlace"/>
          </v-col>
          <v-col cols="12" sm="6">
            <FetchOptionsSelect persistent-placeholder label="Geslacht" action="resourcesModule/fetchGenders" v-model="userPersonalInformation.gender" :translationFunction="translateGender"/>
          </v-col>
          <v-col cols="12" sm="6">
            <FetchOptionsSelect persistent-placeholder label="Burgerlijke staat" action="resourcesModule/fetchCivilStatuses" v-model="userPersonalInformation.civilStatus" :translationFunction="translateCivilStatus"/>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field persistent-placeholder label="Rijksregisternummer" type="text" v-model="userPersonalInformation.taxIdentificationNumber" :required="isCIAOEnabled" :rules="isCIAOEnabled ? getRequiredRules('Rijksregisternummer moet ingevuld worden') : []"/>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field persistent-placeholder label="Bankrekening" type="text" v-model="userPersonalInformation.bankAccountNumber"/>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field persistent-placeholder label="Personen ten laste" type="number" v-model="userPersonalInformation.dependents" :rules="[v=>v>=0 || 'Personen ten laste moet minstens 0 zijn!']" :min="0"/>
          </v-col>
        </v-row>
      </template>
    </UserDetailItemEditCard>
  </div>
</template>

<script>
import DatePicker from "@/components/shared/fields/DatePicker.vue";
import FetchOptionsSelect from "@/components/shared/fields/FetchOptionsSelect.vue";
import routeNames from "@/router/RouteNames";
import UserDetailItemEditCard from "@/views/user/forms/UserDetailItemEditCard.vue"
import CountryCodeSelect from "@/components/shared/fields/CountryCodeSelect.vue";
import {translateNationalitiy, translateGender, translateCivilStatus, translateDependents} from "@/shared/utils/translateUtils";
import {getRequiredRules} from "@/shared/utils/inputRulesUtils.js"


export default {
  name: "UserDetailEditPersonalInformation",
  props: {
      userId: {
      type: String,
      required: true
      }
  },
  data() {
      return {
          userPersonalInformation: {},
          routeNames: routeNames,
          promises: [
            this.$store.dispatch('usersModule/fetchUserPersonalInformation', this.userId).then((result) => {
              this.userPersonalInformation = result
            }),
            this.$store.dispatch('locationRegistrationConfigModule/fetchLocationConfig')
          ]
      }
  },
  components: {
      DatePicker,
      FetchOptionsSelect,
      UserDetailItemEditCard,
      CountryCodeSelect
  },
  methods: {
    translateNationalitiy,
    translateGender,
    translateCivilStatus,
    translateDependents,
    getRequiredRules
  },
  computed: {
    isCIAOEnabled() {
      return this.$store.getters["locationRegistrationConfigModule/getLocationConfig"]?.ciaoConfig?.ciaoEnabled === true
    },
  }
}

</script>