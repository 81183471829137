<template>
    <InvoiceEditCard :confirmation-message="getConfirmationMessage" :title="getFormTitle"
        update-store-action="invoicesModule/sendInvoice" :update-store-action-payload="getInvoiceSendPayload"
        :invoiceId="invoiceId" :promises.sync="promises" :success-callback="invoiceSendSuccessCallback">
        <template v-slot:form-fields>
            <v-row>
                <v-col cols="12" md="6">
                    <v-text-field required v-model="emailAddress" persistent-placeholder label="Aan" type="text"
                        :rules="getEmailRules(true)" />
                </v-col>
            </v-row>
        </template>
        <template v-slot:save-button-content>
            <slot name="save-button-content">
                Verzenden
            </slot>
        </template>
    </InvoiceEditCard>
</template>
<script>
import InvoiceEditCard from "@/views/invoice/InvoiceEditCard.vue";
import { getEmailRules } from '@/shared/utils/inputRulesUtils.js';
import { applyDateFormat } from "@/shared/utils/dateUtils";
import RouteNames from "@/router/RouteNames";



export default {
    name: "InvoiceSend",
    props: {
        invoiceId: {
            type: String,
            required: false,
        },
        credit:{
           type: Boolean,
           default: false 
        }
    },
    data() {
        return {
            RouteNames,
            emailAddress: null,
            promises: []
        }
    },
    methods: {
        getEmailRules,
        applyDateFormat,
        invoiceSendSuccessCallback() {
            this.$router.push({ name: RouteNames.INVOICE_DETAIL, params: { invoiceId: this.invoiceId } })
        },
    },
    components: {
        InvoiceEditCard
    },
    computed: {
        getConfirmationMessage() {
            if (this.credit) {
                return "Creditnota werd verzonden"
            } else {
                return "Factuur werd verzonden"
            }
        },
        getFormTitle() {
            if (this.credit) {
                return "Verstuur creditnota"
            } else {
                return "Verstuur factuur"
            }
        },
        getInvoiceSendPayload() {
            return { invoiceId: this.invoiceId, emailAddress: this.emailAddress }
        },
    }
}
</script>
