<template>
  <TaskDetailItemEditCard confirmation-message="Herhaling details werden succesvol gewijzigd" title="Bewerk herhaling details"
    update-store-action="tasksModule/updateTaskRecurrence"
    :update-store-action-payload="{ projectId: projectId, clientId: clientId, functionalId: functionalId, payload: getRecurrence }"
    :projectId="projectId" :clientId="clientId" :functionalId="functionalId" :promises.sync="promises">
    <template v-slot:form-fields>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="3">
            <span class="d-flex h-100 align-center pl-0">Om de</span>
          </v-col>
          <v-col cols="3">
            <v-text-field required persistent-placeholder type="number"
              v-model="recurrenceDetails.recurrencePeriod" min=1
              :rules="[...getBiggerThanZeroRules('Aantal moet groter zijn dan 0'), ...getRequiredRules('Aantal moet ingevuld worden')]">
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-autocomplete v-model="recurrenceDetails.recurrencePeriodType" :items="recurrencePeriodValues"
              :rules="[...getRequiredRules('Frequentie moet ingevuld worden')]" required>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field required persistent-placeholder type="number" label="Aantal mogelijke herhalingen per dag"
          v-model="recurrenceDetails.timesPerDay" min=1
          :rules="[...getBiggerThanZeroRules('Aantal per dag moet groter zijn dan 0'), ...getRequiredRules('Aantal per dag moet ingevuld worden')]">
        </v-text-field>
      </v-col>

      <!-- mingap -->
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="7">
            <v-text-field required persistent-placeholder label="Minimum tijd tussen herhalingen" type="number"
              v-model="recurrenceDetails.minGapBetweenRepetitions" min=1
              :rules="[...getBiggerThanZeroRules('Aantal moet groter zijn dan 0'), ...getRequiredRules('Aantal moet ingevuld worden')]">
            </v-text-field>
          </v-col>
          <v-col cols="5">
            <v-autocomplete v-model="recurrenceDetails.minGapBetweenRepetitionsType" :items="timeUnitValues"
              :rules="[...getRequiredRules('Tijdseenheid is verplicht')]" required>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-col>

      <!-- maxgap -->
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="7">
            <v-text-field required persistent-placeholder label="Maximum tijd tussen herhalingen" type="number"
              v-model="recurrenceDetails.maxGapBetweenRepetitions" min=1
              :rules="[...getBiggerThanZeroRules('Aantal moet groter zijn dan 0'), ...getRequiredRules('Aantal moet ingevuld worden')]">
            </v-text-field>
          </v-col>
          <v-col cols="5">
            <v-autocomplete v-model="recurrenceDetails.maxGapBetweenRepetitionsType" :items="timeUnitValues"
              :rules="[...getRequiredRules('Tijdseenheid is verplicht')]" required>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="6">
        <v-row>
          <v-col>
            <v-checkbox v-model="recurrenceDetails.sameDayForRecurrentTasks" label="Gebruik dezelfde dag voor herhaalde taken" />
          </v-col>
          <v-col>
            <v-checkbox v-model="recurrenceDetails.samePersonForRecurrentTasks" label="Gebruik dezelfde persoon voor herhaalde taken" />
          </v-col>
        </v-row>
      </v-col>
    </template>
  </TaskDetailItemEditCard>
</template>

<script>
import TaskDetailItemEditCard from "@/views/planning/forms/TaskDetailItemEditCard.vue"
import routeNames from "@/router/RouteNames";
import { getRequiredRules, getBiggerThanZeroRules } from "@/shared/utils/inputRulesUtils.js"
import RecurrencePeriodType from "../../../shared/enums/RecurrencePeriodType"
import { iso8601StringToDurationSingleTimeUnit, durationToISO8601StringSingleTimeUnit } from "../../../shared/utils/timeUtils"
import TimeUnitType from "../../../shared/enums/timeUnitType"
import { translateTimeUnit, translateRecurrencePeriodType } from "../../../shared/utils/translateUtils"

export default {
  name: "TaskDetailEditTaskRecurrence",
  props: {
    projectId: {
      type: String,
      required: true
    },
    clientId: {
      type: String,
      required: true
    },
    functionalId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      routeNames,
      recurrenceDetails: {
          recurrencePeriod: 69,
          recurrencePeriodType: RecurrencePeriodType.DAILY,
          timesPerDay: 1,
          minGapBetweenRepetitions: 1,
          minGapBetweenRepetitionsType: TimeUnitType.HOURS,
          maxGapBetweenRepetitions: 1,
          maxGapBetweenRepetitionsType: TimeUnitType.HOURS,
          samePersonForRecurrentTasks: false,
          sameDayForRecurrentTasks: false,
        },
      promises: [
        this.getTaskInformation()
      ],
      timeUnitValues: [
        {
          text: translateTimeUnit(TimeUnitType.HOURS),
          value: TimeUnitType.HOURS
        },
        {
          text: translateTimeUnit(TimeUnitType.MINUTES),
          value: TimeUnitType.MINUTES
        },
      ],
      recurrencePeriodValues: [
        {
          text: translateRecurrencePeriodType(RecurrencePeriodType.DAILY),
          value: RecurrencePeriodType.DAILY
        },
        {
          text: translateRecurrencePeriodType(RecurrencePeriodType.WEEKLY),
          value: RecurrencePeriodType.WEEKLY
        },
        {
          text: translateRecurrencePeriodType(RecurrencePeriodType.MONTHLY),
          value: RecurrencePeriodType.MONTHLY
        },
        {
          text: translateRecurrencePeriodType(RecurrencePeriodType.YEARLY),
          value: RecurrencePeriodType.YEARLY
        },
      ],
    }
  },
  components: {
    TaskDetailItemEditCard,
  },
  methods: {
    getRequiredRules,
    getBiggerThanZeroRules,
    getTaskInformation() {
      return this.$store.dispatch("tasksModule/fetchTaskRecurrence", { clientId: this.clientId, projectId: this.projectId, functionalId: this.functionalId })
          .then(result => { 
            this.recurrenceDetails = {
              ...result.recurrenceDetails,
              minGapBetweenRepetitions: iso8601StringToDurationSingleTimeUnit(result.recurrenceDetails.minGapBetweenRepetitions).value,
              minGapBetweenRepetitionsType: iso8601StringToDurationSingleTimeUnit(result.recurrenceDetails.minGapBetweenRepetitions).timeUnitType,
              maxGapBetweenRepetitions: iso8601StringToDurationSingleTimeUnit(result.recurrenceDetails.maxGapBetweenRepetitions).value,
              maxGapBetweenRepetitionsType: iso8601StringToDurationSingleTimeUnit(result.recurrenceDetails.maxGapBetweenRepetitions).timeUnitType,
            }
          })
    }
  },
  computed: {
    getRecurrence() {
      return {
        ...this.recurrenceDetails,
        minGapBetweenRepetitions: durationToISO8601StringSingleTimeUnit(this.recurrenceDetails.minGapBetweenRepetitions, this.recurrenceDetails.minGapBetweenRepetitionsType),
        maxGapBetweenRepetitions: durationToISO8601StringSingleTimeUnit(this.recurrenceDetails.maxGapBetweenRepetitions, this.recurrenceDetails.maxGapBetweenRepetitionsType),
      }
    }
  }
}
</script>