<template>
  <div>
    <TaskDetailHeaderComponent :functionalId=functionalId :clientId=clientId :projectId=projectId :returnToTaskOverview = returnToTaskOverview />
    <v-row>
      <v-col class="d-flex">
        <TaskInformationComponent :functionalId=functionalId :projectId=projectId :clientId=clientId class=" flex-grow-1" />
      </v-col>
      <v-col class="d-flex" >
        <TaskWorkSchemaComponent :functionalId=functionalId :projectId=projectId :clientId=clientId  class=" flex-grow-1"  />
      </v-col>
    </v-row>
    <v-row >
      <v-col class="d-flex" >
        <TaskRecurrenceInformationComponent :functionalId=functionalId :projectId=projectId :clientId=clientId  class=" flex-grow-1"  />
      </v-col>
    </v-row>
  </div>

</template>

<script>
import TaskDetailHeaderComponent from "@/components/taskDetail/TaskDetailHeaderComponent.vue"
import TaskInformationComponent from "@/components/taskDetail/TaskInformationComponent.vue"
import TaskWorkSchemaComponent from "@/components/taskDetail/TaskWorkSchemaComponent.vue"
import TaskRecurrenceInformationComponent from "@/components/taskDetail/TaskRecurrenceInformationComponent.vue";

export default {
  name: "TaskDetail",
  components: {
    TaskDetailHeaderComponent,
    TaskInformationComponent,
    TaskWorkSchemaComponent,
    TaskRecurrenceInformationComponent
  },
  props: {
    functionalId: {
      type: String,
      required: true
    },
    projectId: {
      type: String,
      required: true
    },
    clientId: {
      type: String,
      required: true
    },
    returnToTaskOverview: {
      type: Boolean,
      default:false
    }
  }
}
</script>