<template>
  <PromiseContainer :promises.sync="promises">
    <v-row>
      <v-col
        cols="3"
        class="d-flex"
      >
        <CarouselTimeline
          v-model="selectedSubscriptionId"
          property-to-bind="id"
          :timeline-items="getComputedTimelineItems"
        >
          <template #timelineItem="slotProps">
            <span :class="{ 'selected': slotProps.selected }">{{ applyDateFormat(slotProps.timelineItem.startDate) }} -
              {{
                applyDateFormat(slotProps.timelineItem.endDate) }}</span>
          </template>
        </CarouselTimeline>
      </v-col>
      <v-col cols="9">
        <SubscriptionInfo
          :subscription-id="selectedSubscriptionId"
          :enable-subscription-changes="selectedSubscriptionId == getCurrentSubscription?.id"
          :enable-subscription-extension-renewal="selectedSubscriptionId == getLatestSubscription?.id"
        />
      </v-col>
    </v-row>
  </PromiseContainer>
</template>

<script>
import SubscriptionInfo from './SubscriptionInfo.vue';
import CarouselTimeline from '@/components/shared/timelines/CarouselTimeline.vue';
import { applyDateFormat } from "@/shared/utils/dateUtils";
import PromiseContainer from "@/components/shared/PromiseContainer";
import { compareAsc } from 'date-fns';
import { getItemAtOrBeforeDate } from '@/shared/utils/CollectionUtils';
import { compareDesc } from 'date-fns/esm';
import { getItemAt } from '../../../shared/utils/CollectionUtils';

export default {
  name: "SubscriptionTimeline",
  components: {
    PromiseContainer,
    CarouselTimeline,
    SubscriptionInfo
  },
  data() {
    return {
      selectedSubscriptionId: null,
      timelineItems: [],
      promises: [
        this.$store.dispatch("organizationModule/fetchBasicSubscriptionHistory")
          .then(data => {
            this.timelineItems = data
            this.selectedSubscriptionId = this.getCurrentOrLastSubscription?.id
          })
      ]
    }
  },
  computed: {
    getTimelineItemsWithDates() {
      return this.timelineItems.map(item => (
        {
          ...item,
          startDate: new Date(item.startDate),
          endDate: new Date(item.endDate)
        }
      ))
    },
    getComputedTimelineItems() {
      return this.getTimelineItemsWithDates.map(item => (
        {
          ...item,
          active: this.getCurrentOrLastSubscription?.id == item?.id
        }
      ))
        .sort((a, b) => compareAsc(a.endDate, b.endDate))
    },
    getCurrentSubscription() {
      return getItemAt(this.getTimelineItemsWithDates, "startDate", "endDate", Date.now())
    },
    getCurrentOrLastSubscription() {
      return getItemAtOrBeforeDate(this.getTimelineItemsWithDates, "startDate", "endDate", Date.now())
    },
    getLatestSubscription() {
      return [...this.getTimelineItemsWithDates]?.sort((a, b) => compareDesc(a.startDate, b.startDate))[0]
    }
  },
  methods: {
    applyDateFormat,

  }
}
</script>