import axiosInstance from "@/store/axios.instance";
import Parameters from "../../shared/constants/Parameters";

export default {
    namespaced: true,
    state() {
        return {
            parameters: null
        }
    },
    mutations: {
        setParameters(state, parameters) {
            state.parameters = parameters
        },
        setParameter(state, parameter) {
            let parameters = [...state.parameters]
            const index = state.parameters.findIndex(p => p.name == parameter.name)
            parameters[index] = parameter
            state.parameters = parameters
        },
    },
    actions: {
        fetchParamsOrganization({ commit, rootGetters }) {
            return axiosInstance.get('parameters', rootGetters["authModule/getUrlConfig"])
                .then(result => {
                    commit('setParameters', result?.data)
                    return result?.data
                })
        },
        updateParameter({ commit, rootGetters }, parameters) {
            return axiosInstance.put('parameters', parameters, rootGetters["authModule/getUrlConfig"])
                .then(result => commit('setParameter', result?.data))
        },
    },
    getters: {
        getMaxDaysClockingInThePast(state) {
            return state.parameters.find(parameter => parameter.name === Parameters.MaxDaysClockingInThePast).value
        },
        getEnableContiguousHours(state) {
            return state.parameters.find(parameter => parameter.name === Parameters.EnableContiguousHours).value === "true"
        },
        getEnableTeamsOption(state) {
            return state.parameters.find(parameter => parameter.name === Parameters.EnableTeamsOption).value === "true"
        },
        getMaxDaysAbsenceInThePast(state) {
            return state.parameters.find(parameter => parameter.name === Parameters.MaxDaysAbsenceInThePast).value
        },
        getDateFormat(state) {
            return state.parameters?.find(parameter => parameter.name === Parameters.DateFormat).value
        },
        getParameters(state) {
            return state.parameters;
        },
        getParameterValue: (state) => (parameterName) => {
            return state.parameters?.find(parameter => parameter.name === parameterName)?.value
        },
        getDisplayDateInClockingTimeZone(state) {
            return state.parameters.find(parameter => parameter.name === Parameters.DisplayDateInClockingTimeZone).value === "true"
        },
        getEnableManualRegistrationsAndCorrections(state) {
            return state.parameters.find(parameter => parameter.name === Parameters.EnableManualRegistrationsAndCorrections).value === "true"
        }
    },
}