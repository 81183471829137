<template>
  <div>
    <BasicDetailHeaderComponent
        :promises.sync="promises"
        default-avatar="default-client-avatar.svg"
        :title="basicClientDTO.clientName"
        :titleEditRoute="{name: routeNames.CLIENT_DETAIL_EDIT_CLIENT_NAME}"
        :return-link-route="{name: routeNames.CLIENT_OVERVIEW}"
        return-link-text="Terug naar klanten"
        :active="basicClientDTO.active"
        active-text="Actieve klant"
        inactive-text="Inactieve klant"
        menu-button-text="Acties">

      <template v-slot:content>
        <v-col cols="12">
          Id: <Editable :route="getEditIdRoute" class="notranslate">{{ basicClientDTO.functionalId }}</Editable>
        </v-col>
      </template>

      <template v-slot:actions>
        <v-list-item link @click="onToggleActiveClick">
          <v-list-item-icon>
            <v-icon>{{ basicClientDTO.active ? "mdi-folder-cancel" : "mdi-folder-check" }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ basicClientDTO.active ? "Zet op inactief" : "Zet op actief" }}</v-list-item-title>
        </v-list-item>
        <v-list-item link @click="onDeleteClientClick">
          <v-list-item-icon>
            <v-icon>mdi-delete</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Verwijder</v-list-item-title>
        </v-list-item>
      </template>
    </BasicDetailHeaderComponent>

    <ConfirmationDialog :title="getToggleActiveDialogTitle" :description="getToggleActiveDialogDescription"
                        v-model="toggleActiveDialog" :onConfirm="toggleActive"/>
    <ConfirmationDialog :title="getDeleteDialogTitle" :description="getDeleteDialogDescription" v-model="deleteDialog"
                        :onConfirm="deleteClient"/>
  </div>
</template>

<script>
import BasicDetailHeaderComponent from '../shared/BasicDetailHeaderComponent.vue'
import routeNames from "@/router/RouteNames"
import ConfirmationDialog from "@/components/shared/ConfirmationDialog.vue"
import Editable from "@/components/shared/Editable"

export default {
  name: "ClientDetailHeaderComponent",
  components: {
    BasicDetailHeaderComponent,
    ConfirmationDialog,
    Editable,
  },
  props: {
    clientId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      promises: [this.fetchBasicClientDTO()],
      routeNames,
      clientRoute: {name: routeNames.CLIENT_DETAIL, params: {clientId: this.clientId}},
      editClientRoute: {name: routeNames.CLIENT_OVERVIEW},
      clientsRoute: {name: routeNames.CLIENT_OVERVIEW},
      toggleActiveDialog: false,
      deleteDialog: false,
      basicClientDTO: {
        clientName: null,
        active: null,
        functionalId: null
      },
    }
  },
  methods: {
    onToggleActiveClick() {
      this.toggleActiveDialog = true
    },
    toggleActive() {
      this.promises.push(
        this.$store.dispatch("clientsModule/toggleClientActive", this.clientId)
          .then(() => this.promises.push(this.fetchBasicClientDTO()))
      )

    },
    onDeleteClientClick() {
      this.deleteDialog = true
    },
    deleteClient() {
      this.promises.push(
        this.$store.dispatch("clientsModule/deleteClient", this.clientId)
            .then(() => this.$router.push(this.clientsRoute))
      )
    },
    fetchBasicClientDTO() {
      return this.$store.dispatch("clientsModule/fetchBasicClientDTO", this.clientId)
          .then(result => {
            this.basicClientDTO = result
          })
    }
  },
  computed: {
    getToggleActiveDialogTitle() {
      return `Klant ${this.basicClientDTO.clientName} ${this.basicClientDTO.active ? "deactiveren" : "activeren"}`
    },
    getToggleActiveDialogDescription() {
      if (this.basicClientDTO.activeProjectCount <= 0) {
        return `Bent u zeker dat u klant ${this.basicClientDTO.clientName} wilt ${this.basicClientDTO.active ? "deactiveren" : "activeren"}?`
      } else {
        return `Bij het deactiveren van deze klant worden ook alle bijbehorende projecten gedeactiveerd. Bent u zeker?`
      }
    },
    getDeleteDialogTitle() {
      return `Klant ${this.basicClientDTO.clientName} verwijderen`
    },

    getDeleteDialogDescription() {
      return `Bent u zeker dat u klant ${this.basicClientDTO.clientName} wilt verwijderen?`
    },
    getEditIdRoute() {
      return { name: routeNames.CLIENT_DETAIL_EDIT_FUNCTIONAL_ID, params: { clientId: this.clientId } }
    },
  }
}
</script>