<template>
    <div>
        <v-row v-if="!hideTimePickers">
          <v-col lg="4" md="8" sm="12">
            <TimeAndDatePicker v-model="modelValue.startTime" label="Van" required
            :disabled="disableTimePickers" :disabledDate="true"/>
          </v-col>
        </v-row>
        <v-row v-if="!hideTimePickers">
          <v-col lg="4" md="8" sm="12">
            <TimeAndDatePicker v-model="modelValue.endTime" label="Tot" required
            :min="modelValue.startTime"
            :disabled="disableTimePickers"/>
          </v-col>
        </v-row>
        <v-row v-if="clientProjectModuleInstalled">
            <CustomClientAndProjectSelect required v-model="clientAndProject" tabindex="1"
            :autoFillProjectId.sync="autoFillProjectId" :autoFillClientId.sync="autoFillClientId" xs="12" sm="8" lg="4" :disabled="disableClientAndProjectSelect" :includeArchived="includeArchivedClientsAndProjects"/>
        </v-row>
        <v-row v-if="!hideComment">
            <v-col lg="4" md="8" sm="8" xs="12">
            <v-textarea outlined label="Opmerking" clearable clear-icon="mdi-close-circle"
                :rules="[v => (v == null || v.length <= 200) || 'Opmerking kan maximaal uit 200 karakters bestaan']"
                v-model="modelValue.comment"/>
            </v-col>
        </v-row>
        <v-row v-if="!hideBreakTime" dense justify="space-between" align="center">
            <v-col cols="auto">
            <v-btn text v-if="!breakMessage" @click="toggleBreakTime" tabindex="3" class="mt-0 text-capitalize">
                <v-icon v-if="modelValue.breakTime" color="nav-text-active" left>mdi-check</v-icon>
                <v-icon v-else color="red" left>mdi-close</v-icon>
                Pauze
            </v-btn>
            <v-alert v-else>{{ breakMessage }}</v-alert>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import CustomClientAndProjectSelect from '@/components/shared/CustomClientAndProjectSelect.vue'
import TimeAndDatePicker from '../shared/fields/TimeAndDatePicker.vue'
import differenceInMinutes from "date-fns/differenceInMinutes"
import {applyDateFormat} from "@/shared/utils/dateUtils"
import ModuleType from "@/shared/enums/moduleType"

export default {
    name: "WorkClockingForm",
    emits: ['update:value'],
    props: {
        value: {
            type: Object,
        },
        breakTime: {
            type: Boolean,
            required: false,
        },
        hideTimePickers: {
            type: Boolean,
            required: false,
            default: false,
        },
        disableTimePickers: {
            type: Boolean,
            required: false,
            default: false,
        },
        disableClientAndProjectSelect: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideComment: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideBreakTime: {
            type: Boolean,
            required: false,
            default: false,
        },
        includeArchivedClientsAndProjects: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            breakMessage: null,
            clientAndProject: {
                client: null,
                project: null,
            },
            localStoragePrefix: "clocking#",
            preferedBreakTime: this.value.id ? this.breakTime : true,
            clientProjectModuleInstalled: this.$store.getters["organizationModule/organizationHasModule"]([ModuleType.CLIENT_PROJECTMANAGEMENT])
        }
    },
    components: {
        CustomClientAndProjectSelect,
        TimeAndDatePicker,
    },
    created() {
        this.setBreakTimeRuleMessage()
    },
    computed: {
        modelValue: {
            get() {
                return this.value
            },
            set(newValue) {
                this.$emit('update:value', newValue)
            }
        },
        autoFillClientId: {
            get() { 
                if (this.value?.clientId) {
                    return this.value.clientId
                } else if (this.value?.client) {
                    return this.value.client.id
                } else {
                    return localStorage.getItem(this.localStoragePrefix + "autoFillClientId")
                }
            },
            set(input) {
                this.value.clientId = input
                localStorage.setItem(this.localStoragePrefix + "autoFillClientId", input);
            }
        },
        autoFillProjectId: {
            get() {
                if (this.value?.projectId) {
                    return this.value.projectId
                } else {
                    return localStorage.getItem(this.localStoragePrefix + "autoFillProjectId")
                }
            },
            set(input) {
                this.value.projectId = input
                localStorage.setItem(this.localStoragePrefix + "autoFillProjectId", input);
            }
        },
        getBreakLength() {
            return this.$store.state.clockingsModule.breakLength;
        },
        getBreakTimeForDay() {
            return !this.breakTime && this.$store.state.clockingsModule.breakTime
        },
    },
    methods: {
        applyDateFormat,
        setBreakTimeRuleMessage() {
            if (this.getBreakTimeForDay) {
                this.breakMessage = "Pauze is al ingegeven op deze dag."
                this.setBreakTime(false)
            } else if (!this.modelValue?.endTime || !this.modelValue?.startTime) {
                this.breakMessage = null
                if (!this.modelValue.id) {
                    this.setBreakTime(true)
                }
            } else if (differenceInMinutes(this.modelValue?.endTime, this.modelValue?.startTime) >= this.getBreakLength) {
                this.breakMessage = null
                if (!this.modelValue.id) {
                this.setBreakTime(true)
                }
            } else {
                this.breakMessage = `Minimaal ${this.getBreakLength} minuten werken om een pauze te nemen`
                this.setBreakTime(false)
            }
        },
        setBreakTime(newValue) {
            if (newValue && this.preferedBreakTime) {
                this.modelValue.breakTime = true
            } else {
                this.modelValue.breakTime = false
            }
        },
        toggleBreakTime() {
            const newBreakTime = !this.modelValue.breakTime
            this.preferedBreakTime = newBreakTime
            this.modelValue.breakTime = newBreakTime
        },
        setClientAndProjectForClocking(newValue) {
            this.$set(this.value, 'client', newValue.client)
            this.$set(this.value, 'clientId', newValue.client?.id)
            this.$set(this.value, 'projectId', newValue.project?.id)
        },
    },
    watch: {
        "value.endTime": {
            immediate: true,
            handler() {
                this.setBreakTimeRuleMessage()
            }
        },
        "value.startTime": {
            immediate: true,
            handler() {
                this.setBreakTimeRuleMessage()
            }
        },
        getBreakTimeForDay: {
            immediate: true,
            handler() {
                this.setBreakTimeRuleMessage()
            }
        },
        clientAndProject: {
            deep: true,
            immediate: true,
            handler(newValue) {
              if (this.clientProjectModuleInstalled) {
                this.setClientAndProjectForClocking(newValue)
              }
            }
        }
    }
}
</script>