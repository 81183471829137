<template>
    <div>
      <SuborganizationDetailItemEditCard confirmation-message="Suborganisatie werd succesvol aangemaakt"
        :promises.sync="promises" title="Nieuwe suborganisatie aanmaken" :organizationId="organizationId"
        update-store-action="suborganizationModule/createSuborganization"
        :update-store-action-payload="{ organizationId, suborganizationId, createSuborganizationDTO: createSuborganizationDTO }"
        detail-view-route-location="">
        <template v-slot:form-fields>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field v-model="createSuborganizationDTO.name" required persistent-placeholder label="Naam" :rules="getRequiredRules('Naam moet ingevuld worden')"
                type="text" />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="createSuborganizationDTO.vatNumber" persistent-placeholder label="BTW nummer"
                type="text" />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="createSuborganizationDTO.bankAccountNumber" persistent-placeholder label="Bankrekening"
                type="text" />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="createSuborganizationDTO.contactEmail" persistent-placeholder label="Contact email"
                type="text" :rules="getEmailRules(false)" />
            </v-col>
  
            <v-col cols="12">
              <v-subheader class="text-h5 pl-0"><v-icon class="pr-2">mdi-map-marker</v-icon> Adres</v-subheader>
              <AddressInput v-model="createSuborganizationDTO.address" required/>
            </v-col>
          </v-row>
        </template>
      </SuborganizationDetailItemEditCard>
    </div>
  </template>
    
  <script>
  import SuborganizationDetailItemEditCard from './forms/SuborganizationDetailItemEditCard.vue'
  import AddressInput from "@/components/shared/fields/AddressInput.vue";
  import { applyDateFormat } from "@/shared/utils/dateUtils"
  import { getEmailRules, getRequiredRules } from "@/shared/utils/inputRulesUtils"
  
  
  export default {
    name: "SuborganizationDetailEditInformation",
    props: {
      organizationId: {
        type: String,
        required: true
      },
      suborganizationId: {
        type: String,
        required: false
      },
    },
    components: {
      SuborganizationDetailItemEditCard,
      AddressInput
    },
    data() {
      return {
        createSuborganizationDTO: {
          registrationDate: null,
          vatNumber: null,
          contactEmail: null,
          address: {},
        },
        promises: [
        ]
      }
    },
    methods: {
      applyDateFormat,
      getEmailRules,
      getRequiredRules,
    },
  }
  </script>
    