<template>
  <DetailItemEditCard :item-name="isClientIdPresent ? 'project' : 'taken'" :promises.sync="promises"
    title="Nieuwe taak aanmaken"
    :detailViewRouteLocation="isClientIdPresent ? { name: RouteNames.PROJECT_DETAIL, params: { clientId: clientId, projectId: projectId } } : { name: RouteNames.TASK_OVERVIEW }"
    confirmationMessage="Taak werdt succesvol aangemaakt" update-store-action="tasksModule/createTask"
    :update-store-action-payload="newTaskPayload" :success-callback="taskCreatedSuccessCallback">
    <template v-slot:form-fields>
      <v-row>
        <v-col cols="12" md="6">
          <v-autocomplete :items="clients" type="text" v-model="newTask.clientId" label="Klant" required
            :rules="clientRules" item-text="clientName" item-value="id" tabindex="1" :disabled="isClientIdPresent">
            <template v-slot:item="slotProps">
              <v-list-item-content class="notranslate" v-text="slotProps.item.clientName"></v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <v-autocomplete :items="getProjects" type="text" v-model="newTask.projectId" label="Project" required
            :rules="projectRules" item-text="projectName" item-value="id" tabindex="1" :disabled="isClientIdPresent || !isClientSelected">
            <template v-slot:item="slotProps">
              <v-list-item-content class="notranslate" v-text="slotProps.item.projectName" />
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field v-model="newTask.taskName" persistent-placeholder label="Taak naam" type="text"
            :rules="taskNameRules" required @blur="fetchGeneratedFunctionalId()" />
        </v-col>

        <v-col cols="12" md="6">
          <FunctionalIdInput 
            v-model="newTask.functionalId"
            :suggestion="lastFetchedFunctionalIdSuggestion"
            label="Taak id" prefix="T-"
            required
            :rules="functionalIdRules"/>
        </v-col>
        <v-col cols="12" md="6">
          <DatePicker v-model="newTask.startDate" label="Start datum" :default-now="false" />
        </v-col>
        <v-col cols="12" md="6">
          <DatePicker v-model="newTask.endDate" label="Eind datum"
            :min="newTask.startDate ? new Date(newTask.startDate) : null" :default-now="false" />
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field required persistent-placeholder label="Aantal nodig personeel" type="number"
            v-model="newTask.usersRequired" min=1
            :rules="[...getBiggerThanZeroRules('Aantal moet groter zijn dan 0'), ...getRequiredRules('Aantal moet ingevuld worden')]">
          </v-text-field>

        </v-col>

        <v-col cols="12" md="6">
          <v-autocomplete v-model="newTask.taskFrequency" :items="taskFrequencyValues" label="Frequentie"
            :rules="[...getRequiredRules('Frequentie moet ingevuld worden')]" required>
          </v-autocomplete>
        </v-col>

        <!-- Duurtijd -->
        <v-col cols="12">
          <v-row>
            <v-col cols="1">
              <span class="d-flex h-100 align-center pl-0">Duurtijd:</span>
            </v-col>
            <v-col cols="9" class="px-4">
              <v-range-slider step="15"  hide-details dense min="0" max="600" v-model="newTask.durationMinMax" class="d-flex h-100 align-center pl-0" > 
                <template v-slot:prepend>
                  <v-text-field
                    :value="newTask.durationMinMax[0]"
                    class="mt-0 pt-0"
                    hide-details
                    single-line
                    type="number"
                    min="0"
                    style="width: 60px"
                    @change="$set(newTask.durationMinMax, 0, $event)"
                  ></v-text-field>
                </template>
                <template v-slot:append>
                  <v-text-field
                    :value="newTask.durationMinMax[1]"
                    class="mt-0 pt-0"
                    hide-details
                    single-line
                    type="number"
                    min="0"
                    style="width: 60px"
                    @change="$set(newTask.durationMinMax, 1, $event)"
                  ></v-text-field>
                </template>
              </v-range-slider>
            </v-col>
            <v-col cols="2">
              <v-autocomplete v-model="newTask.durationUnit" :items="timeUnitValues"
                :rules="[...getRequiredRules('Tijdseenheid is verplicht')]" required disabled>
              </v-autocomplete>
            </v-col>
          </v-row>
          
        </v-col>



        <v-col cols="12">
          <v-row>
            <v-expansion-panels readonly :disabled="disableRecurrencePanel" v-model="recurrencePanel">
               <v-expansion-panel>
                <v-expansion-panel-header>Herhaling details</v-expansion-panel-header>
                <v-expansion-panel-content>

                  <v-col cols="12" md="6">
                    <v-row>
                      <v-col cols="3">
                        <span class="d-flex h-100 align-center pl-0">Om de</span>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field required persistent-placeholder type="number"
                          v-model="newTask.recurrenceDetails.recurrencePeriod" min=1
                          :rules="[...getBiggerThanZeroRules('Aantal moet groter zijn dan 0'), ...getRequiredRules('Aantal moet ingevuld worden')]">
                        </v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-autocomplete v-model="newTask.recurrenceDetails.recurrencePeriodType" :items="recurrencePeriodValues"
                          :rules="[...getRequiredRules('Frequentie moet ingevuld worden')]" required>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field required persistent-placeholder type="number" label="Aantal mogelijke herhalingen per dag"
                      v-model="newTask.recurrenceDetails.timesPerDay" min=1
                      :rules="[...getBiggerThanZeroRules('Aantal moet groter zijn dan 0'), ...getRequiredRules('Aantal moet ingevuld worden')]">
                    </v-text-field>
                  </v-col>

                  <!-- mingap -->
                  <v-col cols="12" md="6">
                    <v-row>
                      <v-col cols="7">
                        <v-text-field required persistent-placeholder label="Minimum tijd tussen herhalingen" type="number"
                          v-model="newTask.recurrenceDetails.minGapBetweenRepetitions" min=1
                          :rules="[...getBiggerThanZeroRules('Aantal moet groter zijn dan 0'), ...getRequiredRules('Aantal moet ingevuld worden')]">
                        </v-text-field>
                      </v-col>
                      <v-col cols="5">
                        <v-autocomplete v-model="newTask.recurrenceDetails.minGapBetweenRepetitionsType" :items="timeUnitValues"
                          :rules="[...getRequiredRules('Tijdseenheid is verplicht')]" required>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-col>

                  <!-- maxgap -->
                  <v-col cols="12" md="6">
                    <v-row>
                      <v-col cols="7">
                        <v-text-field required persistent-placeholder label="Maximum tijd tussen herhalingen" type="number"
                          v-model="newTask.recurrenceDetails.maxGapBetweenRepetitions" min=1
                          :rules="[...getBiggerThanZeroRules('Aantal moet groter zijn dan 0'), ...getRequiredRules('Aantal moet ingevuld worden')]">
                        </v-text-field>
                      </v-col>
                      <v-col cols="5">
                        <v-autocomplete v-model="newTask.recurrenceDetails.maxGapBetweenRepetitionsType" :items="timeUnitValues"
                          :rules="[...getRequiredRules('Tijdseenheid is verplicht')]" required>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12">
                    <v-row>
                      <v-col>
                        <v-checkbox v-model="newTask.recurrenceDetails.sameDayForRecurrentTasks" label="Gebruik dezelfde dag voor herhaalde taken" />
                      </v-col>
                      <v-col>
                        <v-checkbox v-model="newTask.recurrenceDetails.samePersonForRecurrentTasks" label="Gebruik dezelfde persoon voor herhaalde taken" />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
        </v-col>

        <v-col lg="4" md="8" sm="8" xs="12">
          <v-textarea outlined label="Beschrijving" clearable clear-icon="mdi-close-circle"
            :rules="[v => (v == null || v.length <= 600) || 'Beschrijving kan maximaal uit 600 karakters bestaan']"
            v-model="newTask.description" />
        </v-col>
      </v-row>
    </template>
  </DetailItemEditCard>
</template>

<script>
import DetailItemEditCard from "@/components/shared/DetailItemEditCard.vue"
import DatePicker from "@/components/shared/fields/DatePicker.vue"
import RouteNames from "@/router/RouteNames"
import { getRequiredRules, getBiggerThanZeroRules, getStartsWithRules, getMaxStringSizeRules, getMinStringSizeRules } from "@/shared/utils/inputRulesUtils.js"
import TaskFrequencyType from "../../../shared/enums/taskFrequencyType"
import RecurrencePeriodType from "../../../shared/enums/RecurrencePeriodType"
import TimeUnitType from "../../../shared/enums/timeUnitType"
import { translateTaskFrequency, translateTimeUnit, translateRecurrencePeriodType } from "../../../shared/utils/translateUtils"
import FunctionalIdInput from "../../../components/shared/fields/FunctionalIdInput.vue"
import { durationToISO8601StringSingleTimeUnit, durationToISO8601String } from "../../../shared/utils/timeUtils"


export default {
  name: "TaskCreate",
  components: {
    DetailItemEditCard,
    DatePicker,
    FunctionalIdInput,
  },
  props: {
    projectId: {
      type: String,
      required: false
    },
    clientId: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      newTask: {
        clientId: this.clientId,
        projectId: this.projectId,
        functionalId: null,
        taskName: null,
        startDate: null,
        endDate: null,
        durationMinMax: [1, 2],
        durationUnit: TimeUnitType.HOURS,
        taskFrequency: TaskFrequencyType.RECURRENT,
        description: null,
        usersRequired: 1,
        recurrenceDetails: {
          recurrencePeriod: 1,
          recurrencePeriodType: RecurrencePeriodType.DAILY,
          timesPerDay: 1,
          minGapBetweenRepetitions: 1,
          minGapBetweenRepetitionsType: TimeUnitType.HOURS,
          maxGapBetweenRepetitions: 1,
          maxGapBetweenRepetitionsType: TimeUnitType.HOURS,
          samePersonForRecurrentTasks: false,
          sameDayForRecurrentTasks: false,
        },
      },
      lastFetchedFunctionalIdSuggestion: null,
      clients: [],
      projects: [],
      RouteNames,
      clientRules: getRequiredRules("Klant moet ingevuld worden"),
      projectRules: getRequiredRules("Project moet ingevuld worden"),
      taskNameRules: getRequiredRules("Naam van de taak is verplicht"),
      promises: [this.fetchClients()],
      TaskFrequencyType,
      TimeUnitType,
      taskFrequencyValues: [
        {
          text: translateTaskFrequency(TaskFrequencyType.RECURRENT),
          value: TaskFrequencyType.RECURRENT
        },
        {
          text: translateTaskFrequency(TaskFrequencyType.ONE_TIME),
          value: TaskFrequencyType.ONE_TIME
        }
      ],
      timeUnitValues: [
        {
          text: translateTimeUnit(TimeUnitType.MINUTES),
          value: TimeUnitType.MINUTES
        },
        {
          text: translateTimeUnit(TimeUnitType.HOURS),
          value: TimeUnitType.HOURS
        },
        {
          text: translateTimeUnit(TimeUnitType.DAYS),
          value: TimeUnitType.DAYS
        },
      ],
      recurrencePeriodValues: [
        {
          text: translateRecurrencePeriodType(RecurrencePeriodType.DAILY),
          value: RecurrencePeriodType.DAILY
        },
        {
          text: translateRecurrencePeriodType(RecurrencePeriodType.WEEKLY),
          value: RecurrencePeriodType.WEEKLY
        },
        {
          text: translateRecurrencePeriodType(RecurrencePeriodType.MONTHLY),
          value: RecurrencePeriodType.MONTHLY
        },
        {
          text: translateRecurrencePeriodType(RecurrencePeriodType.YEARLY),
          value: RecurrencePeriodType.YEARLY
        },
      ],
      functionalIdRules: [
        ...getRequiredRules('Id van de taak moet ingevuld worden'),
        ...getStartsWithRules('T-', 'Id van een taak moet beginnen met T-'),
        ...getMinStringSizeRules(3, 'Id van een taak moet minimaal 1 teken bevatten'),
        ...getMaxStringSizeRules(6, 'Id van een taak mag maximaal 4 tekens bevatten'),
      ]
    }
  },
  methods: {
    taskCreatedSuccessCallback(taskCreatedDTO) {
      this.$router.push({ name: RouteNames.TASK_DETAIL, params: { clientId: taskCreatedDTO.data.clientId, projectId: taskCreatedDTO.data.projectId, functionalId: taskCreatedDTO.data.functionalId } })
    },
    fetchClients() {
      return this.$store.dispatch("clientsModule/fetchNotArchivedClients")
        .then(result => {
          this.clients = result
        })
    },
    fetchProjects() {
      this.$store.dispatch("clientsModule/fetchClient", this.clientId)
        .then(result => this.projects = result.projects)
    },
    fetchGeneratedFunctionalId() {
      if (this.newTask.taskName && this.newTask.projectId) {
        this.$store.dispatch("tasksModule/getFunctionalIdSuggestions", { clientId: this.newTask.clientId, projectId: this.newTask.projectId, seed: this.newTask.taskName })
          .then(result => {
            this.newTask.functionalId = result
            this.lastFetchedFunctionalIdSuggestion = result
          })
      } else {
        this.newTask.functionalId = null
      }
    },
    getBiggerThanZeroRules,
    getRequiredRules,
    getStartsWithRules,
    getMinStringSizeRules,
    getMaxStringSizeRules,
  },
  computed: {
    isClientIdPresent() {
      return this.clientId != null
    },
    isClientSelected() {
      return this.newTask.clientId != null
    },
    getProjects() {
      if (this.newTask.clientId) {
        return this.clients.filter(client => client.id === this.newTask.clientId).flatMap(client => client.projects || []);
      } else {
        return []
      }
    },
    recurrencePanel() {
      return this.newTask.taskFrequency === TaskFrequencyType.RECURRENT ? 0 : null
    },
    disableRecurrencePanel() {
      return this.newTask.taskFrequency === TaskFrequencyType.ONE_TIME
    },
    newTaskPayload() {
      let returnedNewTask = this.newTask

      // frequency check
      if (this.newTask.taskFrequency === TaskFrequencyType.ONE_TIME) {
        // When ONE_TIME, recurrence details not necessary
        returnedNewTask = {
          ...returnedNewTask,
          recurrenceDetails: null
        }
      } else {
        // When Recurring, convert to Duration readable by backend
        returnedNewTask = {
          ...returnedNewTask,
          recurrenceDetails: {
            ...returnedNewTask.recurrenceDetails,
            minGapBetweenRepetitions: durationToISO8601StringSingleTimeUnit(returnedNewTask.recurrenceDetails.minGapBetweenRepetitions, returnedNewTask.recurrenceDetails.minGapBetweenRepetitionsType),
            maxGapBetweenRepetitions: durationToISO8601StringSingleTimeUnit(returnedNewTask.recurrenceDetails.maxGapBetweenRepetitions, returnedNewTask.recurrenceDetails.maxGapBetweenRepetitionsType),
          }
        }
      }

      // format array of the slider to two fields
      returnedNewTask = {
        ...returnedNewTask,
        minimumDurationAmount: durationToISO8601String(0, Math.trunc(returnedNewTask.durationMinMax[0] / 60), returnedNewTask.durationMinMax[1] % 60, 0),
        maximumDurationAmount: durationToISO8601String(0, Math.trunc(returnedNewTask.durationMinMax[1] / 60), returnedNewTask.durationMinMax[1] % 60, 0),
      }

      return returnedNewTask
    }
  },
  watch: {
    // Watch for changes to newTask.projectId
    'newTask.projectId'() {
      this.fetchGeneratedFunctionalId()
    },
    // Watch for changes to newTask.clientId
    'newTask.clientId'() {
      if(!this.isClientIdPresent && !!this.newTask.projectId) {
        this.newTask.projectId = null
      }
    },
  },
}
</script>