<template>
  <TaskDetailItemEditCard confirmation-message="Taakinformatie werd succesvol gewijzigd" title="Bewerk taakinformatie"
    update-store-action="tasksModule/updateTaskInformation"
    :update-store-action-payload="{ projectId: projectId, clientId: clientId, functionalId: functionalId, payload: getTask }"
    :projectId="projectId" :clientId="clientId" :functionalId="functionalId" :promises.sync="promises">
    <template v-slot:form-fields>
      <v-row>
        <v-col cols="12" md="6">
          <DatePicker v-model="taskInformation.startDate" label="Start datum" :default-now="false" />
        </v-col>
        <v-col cols="12" md="6">
          <DatePicker v-model="taskInformation.endDate" label="Eind datum"
            :min="taskInformation.startDate ? new Date(taskInformation.startDate) : null" :default-now="false" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-autocomplete v-model="taskInformation.taskFrequency" :items="taskFrequencyValues" label="Frequentie"
            :rules="[...getRequiredRules('Frequentie moet ingevuld worden')]" required>
          </v-autocomplete>
        </v-col>
        <v-col cols="3">
          <v-text-field required persistent-placeholder label="Duurtijd" type="number"
            v-model="taskInformation.durationAmount" min=1
            :rules="[...getBiggerThanZeroRules('Aantal moet groter zijn dan 0'), ...getRequiredRules('Aantal moet ingevuld worden')]">
          </v-text-field>
        </v-col>
        <v-col cols="3">
          <v-autocomplete v-model="taskInformation.durationUnit" :items="timeUnitValues"
            :rules="[...getRequiredRules('Tijdseenheid is verplicht')]" required disabled>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col lg="4" md="8" sm="8" xs="12">
          <v-textarea outlined label="Beschrijving" clearable clear-icon="mdi-close-circle"
            :rules="[v => (v == null || v.length <= 600) || 'Beschrijving kan maximaal uit 600 karakters bestaan']"
            v-model="taskInformation.description" />
        </v-col>
      </v-row>
    </template>
  </TaskDetailItemEditCard>
</template>

<script>
import TaskDetailItemEditCard from "@/views/planning/forms/TaskDetailItemEditCard.vue"
import routeNames from "@/router/RouteNames";
import { getRequiredRules, getBiggerThanZeroRules } from "@/shared/utils/inputRulesUtils.js"
import DatePicker from "@/components/shared/fields/DatePicker.vue";
import { DetailedTimeStamp } from "@/shared/types/DetailedTimeStamp";
import { formatDateAsDay } from "@/shared/utils/dateUtils"
import TaskFrequencyType from "../../../shared/enums/taskFrequencyType"
import TimeUnitType from "../../../shared/enums/timeUnitType"
import { translateTaskFrequency, translateTimeUnit } from "../../../shared/utils/translateUtils"

export default {
  name: "TaskDetailEditTaskInformation",
  props: {
    projectId: {
      type: String,
      required: true
    },
    clientId: {
      type: String,
      required: true
    },
    functionalId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      routeNames,
      taskInformation: {
        startDate: null,
        endDate: null,
        taskfrequency: null,
        durationAmount: null,
        durationUnit: null,
        description: null
      },
      promises: [
        this.getTaskInformation()
      ],
      taskFrequencyValues: [
        {
          text: translateTaskFrequency(TaskFrequencyType.WEEKLY),
          value: TaskFrequencyType.WEEKLY
        },
        {
          text: translateTaskFrequency(TaskFrequencyType.ONE_TIME),
          value: TaskFrequencyType.ONE_TIME
        }
      ],
      timeUnitValues: [
        {
          text: translateTimeUnit(TimeUnitType.HOURS),
          value: TimeUnitType.HOURS
        },
        {
          text: translateTimeUnit(TimeUnitType.MINUTES),
          value: TimeUnitType.MINUTES
        },
      ]
    }
  },
  components: {
    TaskDetailItemEditCard,
    DatePicker

  },
  methods: {
    getRequiredRules,
    getBiggerThanZeroRules,
    getTaskInformation() {
      return this.$store.dispatch("tasksModule/fetchTaskGeneral", { clientId: this.clientId, projectId: this.projectId, functionalId: this.functionalId })
        .then(result => {
          const startDateValue = result?.startDate
            ? formatDateAsDay(DetailedTimeStamp.fromJson(result.startDate).getLocalTimeAsDate())
            : null; // default value
          const endDateValue = result?.endDate
            ? formatDateAsDay(DetailedTimeStamp.fromJson(result.endDate).getLocalTimeAsDate())
            : null; // default value
          this.taskInformation = {
            ...result,
            startDate: startDateValue,
            endDate: endDateValue,

          }
        })
    }
  },
  computed: {
    // Returns data back to TaskDetailItemEditCard where it will be dispatched to the appropriate action
    getTask() {
      const startDateValue = this.taskInformation?.startDate
            ? DetailedTimeStamp.fromUTCTime(new Date(this.taskInformation?.startDate))
            : null; // default value
          const endDateValue = this.taskInformation?.endDate
            ? DetailedTimeStamp.fromUTCTime(new Date(this.taskInformation?.endDate))
            : null; // default value
      
      return {
        ...this.taskInformation,
        startDate: startDateValue,
        endDate: endDateValue
      }

    }
  }
}
</script>