<template>
    <StepperForm v-model="accountForm" v-bind="$attrs" v-on="$listeners">
        <template v-slot:default>
            <v-row>
                <v-col cols="12" lg="7">
                    <v-row>
                        <v-col>
                            <h2 class="mb-2">Account gegevens</h2>
                        </v-col>
                    </v-row>
                    <v-row align="baseline" dense>
                        <v-col cols="12" sm="4">
                            <label class="required_star">Bedrijfsnaam:</label>
                        </v-col>
                        <v-col cols="12" sm="8">
                            <v-text-field required hide-details="auto" dense outlined type="text"
                                v-model="accountForm.organizationName"
                                :rules="getRequiredRules('Bedrijfsnaam moet ingevuld worden')" />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <label class="required_star">BTW nummer:</label>
                        </v-col>
                        <v-col cols="12" sm="8">
                            <v-text-field required hide-details="auto" dense outlined type="text" v-model="accountForm.vatNumber" :rules="getRequiredRules('BTW nummer moet ingevuld worden')"/>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <label class="required_star">Voornaam:</label>
                        </v-col>
                        <v-col cols="12" sm="8">
                            <v-text-field required hide-details="auto" dense outlined type="text"
                                v-model="accountForm.firstName"
                                :rules="getRequiredRules('Voornaam moet ingevuld worden')" />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <label class="required_star">Achternaam:</label>
                        </v-col>
                        <v-col cols="12" sm="8">
                            <v-text-field required hide-details="auto" dense outlined type="text"
                                v-model="accountForm.lastName"
                                :rules="getRequiredRules('Achternaam moet ingevuld worden')" />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <label class="required_star">Email:</label>
                        </v-col>
                        <v-col cols="12" sm="8">
                            <v-text-field required hide-details="auto" dense outlined type="email"
                                v-model="accountForm.email" :rules="getEmailRules()" />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <label class="required_star">Telefoon:</label>
                        </v-col>
                        <v-col cols="12" sm="8">
                            <v-text-field required hide-details="auto" dense outlined type="text"
                                v-model="accountForm.phoneNumber"
                                :rules="getRequiredRules('Telefoonnummer moet ingevuld worden')" />
                        </v-col>
                        <v-col cols="12" sm="12">
                            <PasswordInput v-model="accountForm.password" dense outlined dense-row hide-details="auto" prepend-labels
                                :labels="false" cols="8" />
                        </v-col>
                        <v-col v-if="displayAccountTypeRadioBtn" cols="12">
                            <label class="required_star">Type account:</label>
                            <v-radio-group hide-details="auto" v-model="accountForm.accountType" mandatory
                                class="mt-0 pt-0" @change="changeSubscriptionType()">
                                <v-radio label="Gratis proefversie" value="trial" class="ml-6"/>
                                <v-radio label="Abonnement" value="subscription" class="ml-6"/>
                            </v-radio-group>
                        </v-col>
                        <v-col cols="12">
                            <EulaCheckbox v-model="eulaAccepted" class="mb-3"/>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </template>
    </StepperForm>
</template>

<script>
import RouteNames from "@/router/RouteNames";
import { getEmailRules, getRequiredRules } from "@/shared/utils/inputRulesUtils";
import PasswordInput from "../useroverview/PasswordInput.vue";
import routeNames from "@/router/RouteNames";
import StepperForm from "@/components/shared/StepperForm";
import EulaCheckbox from "@/components/shared/fields/EulaCheckbox.vue";

export default {
    name: "PaymentAccountForm",
    model: {
        prop: 'accountForm',
        event: 'update:accountForm'
    },
    props: {
        completed: Boolean,
        accountForm: Object,
        displayAccountTypeRadioBtn: {
            type: Boolean, 
            required: true
        }

    },
    emits: ['update:completed'],
    data() {
        return {
            RouteNames,
            confirmPassword: null,
            eulaAccepted: false
        }
    },
  created(){
    this.accountForm.accountType = this.$route.name !== routeNames.TRIAL? 'trial' : 'subscription'
  },
    methods: {
        getEmailRules,
        getRequiredRules,
      changeSubscriptionType(){
        switch (this.accountForm.accountType) {
            case "trial":
                this.accountForm.accountType = 'trial'
                break
            case "subscription":
                this.accountForm.accountType = 'subscription'
                break
            default:
                this.accountForm.accountType = 'subscription'
                break
        }
      }
    },
    components: {
        StepperForm,
        PasswordInput,
        EulaCheckbox
    },
}
</script>