<template>
  <div>
    <AvatarDetailEditForm :promises.sync="promises"
                          confirmationMessage="Logo werd succesvol gewijzigd"
                          title="Bewerk logo" :item-name="organizationName"
                          :detailViewRoute="{name: routeNames.ORGANIZATION_DETAIL, params: { organizationId: organizationId}}"
                          update-store-action-name="organizationModule/updateOrganizationLogo" :update-store-action-payload="{id: this.organizationId}"
                          :id="organizationId" :hideSaveButton="true" retrieve-avatar="organizationModule/getOrganizationLogo" :SASKey="SASKey"
                          :update-avatar-payload="{id: organizationId}">
    </AvatarDetailEditForm>
  </div>
</template>

<script>
import routeNames from "@/router/RouteNames";
import AvatarDetailEditForm from "@/components/shared/AvatarDetailEditForm";


export default {
  name: "OrganizationDetailEditLogo",
  components: {
    AvatarDetailEditForm,
  },
  data() {
    return {
      routeNames: routeNames,
      organizationId: this.$route.params.organizationId,
      organizationName: "",
      promises: [
        this.$store.dispatch('organizationModule/fetchOrganizationLogo'),
        this.$store.dispatch("fileExportModule/fetchSASKey")
            .then(result => this.SASKey = result),
      ],
      SASKey: null,
    }
  },
  created() {
    this.getOrganizationName()
  },
  methods:{
    getOrganizationName(){
        this.$store.dispatch('organizationModule/fetchOrganizationName', this.organizationId)
          .then((result) => {this.organizationName = result.name})
    },
  }
}
</script>