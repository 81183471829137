import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import clientsModule from "@/store/modules/clients.module";
import authModule from "@/store/modules/auth.module";
import clockingsModule from "@/store/modules/clockings.module";
import calendarModule from "@/store/modules/calendar.module";
import usersModule from "@/store/modules/users.module";
import workSchedulesModule from "@/store/modules/workSchedules.module";
import fileExportModule from "@/store/modules/file.export.module";
import organizationModule from "@/store/modules/organization.module";
import teamsModule from "@/store/modules/teams.module"
import consultHoursModule from "@/store/modules/consultHours.module";
import paymentModule from "@/store/modules/payment.module";
import workScheduleModule from "@/store/modules/workSchedules.module";
import resourcesModule from './modules/resources.module';
import projectsModule from './modules/projects.module';
import parameterModule from './modules/parameter.module';
import invoicesModule from './modules/invoices.module';
import ciaoModule from './modules/ciao.module';
import invoiceConfigModule from './modules/invoice.config.module';
import locationRegistrationConfigModule from './modules/locationRegistration.config.module';
import tasksModule from '@/store/modules/tasks.module'
import suborganizationModule from '@/store/modules/suborganization.module';


Vue.use(Vuex)

const store = new Vuex.Store({
    plugins: [
        createPersistedState({
            storage: window.sessionStorage,
        })
    ],
    modules: {
        clientsModule: clientsModule,
        authModule: authModule,
        clockingsModule: clockingsModule,
        calendarModule: calendarModule,
        usersModule: usersModule,
        workSchedulesModule: workSchedulesModule,
        fileExportModule: fileExportModule,
        organizationModule: organizationModule,
        teamsModule: teamsModule,
        consultHoursModule: consultHoursModule,
        paymentModule: paymentModule,
        workScheduleModule: workScheduleModule,
        resourcesModule: resourcesModule,
        projectsModule,
        parameterModule,
        invoicesModule,
        ciaoModule,
        invoiceConfigModule,
        locationRegistrationConfigModule,
        tasksModule,
        suborganizationModule
    },
    state() {
        return {
            error: null,
            successMessage: null,
            remainingPromises: 0,
        }
    },
    getters: {},
    mutations: {
        setError(state, error) {
            state.error = error
        },
        setSuccessMessage(state, successMessage) {
            state.successMessage = successMessage
        },
        clearError(state) {
            state.error = null
        },
        clearSuccessMessage(state) {
            state.successMessage = null
        },
        addRemainingPromises(state, count) {
            state.remainingPromises += count
            },
        removeRemainingPromises(state, count) {
            state.remainingPromises = Math.max(0, state.remainingPromises - count)
        },
        enableFullPageLoad(state) {
            state.fullPageLoad = true
        }
    },
    actions: {
        setError({commit}, error) {
            commit('setError', error)
            if (error != null) {
                setTimeout(() => {
                    commit('clearError')
                }, 8000)
            }
        },
        setSuccessMessage({commit}, data) {
            commit('setSuccessMessage', data.message)
            if (data.message != null) {
                setTimeout(() => {
                    commit('clearSuccessMessage')
                }, !data.timeout ? 4000 : data.timeout)
            }
        },
        logOut() {
            // when user logs out, set user in authModule to null
            localStorage.removeItem('token')
            this.commit('authModule/setUser', null)
            this.dispatch('clearState')
        },
        clearState() {
            // apply crucial state clear functions
            // long term - can be replaced with a general state reset function (pinia)
            this.commit('organizationModule/clearSubscriptionStatus')
        }
    },
})
export default store
