<template>
    <div>
      <SuborganizationDetailItemEditCard confirmation-message="Organisatie informatie werd succesvol gewijzigd"
        :promises.sync="promises" title="Bewerk organisatie informatie" :organizationId="organizationId" :suborganizationId="suborganizationId"
        update-store-action="suborganizationModule/updateSuborganization"
        :update-store-action-payload="{ organizationId, suborganizationId, updateSuborganizationDTO: updateSuborganizationDTO }"
        detail-view-route-location="">
        <template v-slot:form-fields>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field v-model="updateSuborganizationDTO.vatNumber" persistent-placeholder label="btw nummer"
                type="text" />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="updateSuborganizationDTO.bankAccountNumber" persistent-placeholder label="Bankrekening"
                type="text" />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="updateSuborganizationDTO.contactEmail" persistent-placeholder label="Contact email"
                type="text" :rules="getEmailRules(false)" />
            </v-col>
  
            <v-col cols="12">
              <v-subheader class="text-h5 pl-0"><v-icon class="pr-2">mdi-map-marker</v-icon> Adres</v-subheader>
              <AddressInput v-model="updateSuborganizationDTO.address" required/>
            </v-col>
          </v-row>
        </template>
      </SuborganizationDetailItemEditCard>
    </div>
  </template>
    
  <script>
  import SuborganizationDetailItemEditCard from './forms/SuborganizationDetailItemEditCard.vue'
  import AddressInput from "@/components/shared/fields/AddressInput.vue";
  import { applyDateFormat } from "@/shared/utils/dateUtils"
  import { getEmailRules } from "@/shared/utils/inputRulesUtils"
  
  
  export default {
    name: "SuborganizationDetailEditInformation",
    props: {
      organizationId: {
        type: String,
        required: true
      },
      suborganizationId: {
        type: String,
        required: false
      },
    },
    components: {
      SuborganizationDetailItemEditCard,
      AddressInput
    },
    data() {
      return {
        updateSuborganizationDTO: {
          registrationDate: null,
          vatNumber: null,
          contactEmail: null,
          address: {},
        },
        promises: [this.fetchExistingSuborganization()]
      }
    },
    methods: {
      applyDateFormat,
      getEmailRules,
      fetchExistingSuborganization() {
        return this.$store.dispatch('suborganizationModule/fetchSuborganization', {organizationId: this.organizationId, suborganizationId: this.suborganizationId})
            .then((updateSuborganizationDTO) => {
              this.updateSuborganizationDTO = updateSuborganizationDTO
              if (!this.updateSuborganizationDTO.address) {
                this.updateSuborganizationDTO.address = {}
              }
            })
      }
    },
  }
  </script>
    