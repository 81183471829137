<template>
  <TaskDetailItemEditCard confirmation-message="Taak id werd succesvol gewijzigd" title="Bewerk taak id"
    update-store-action="tasksModule/updateFunctionalId"
    :update-store-action-payload="{ taskId: taskId, projectId: projectId, clientId: clientId, functionalId: functionalId }"
    :projectId="projectId" :clientId="clientId" :functionalId="functionalId" :promises.sync="promises"
    :successRouteLocation="{ name: routeNames.TASK_DETAIL, params: { projectId: projectId, clientId: clientId, functionalId: taskId } }">
    <template v-slot:form-fields>
      <v-row>
        <v-col cols="10" md="3">
          <FunctionalIdInput 
            v-model="taskId"
            :suggestion="lastFetchedFunctionalIdSuggestion"
            label="Taak id" prefix="T-"
            :rules="functionalIdRules"
            required />
        </v-col>
      </v-row>
    </template>
  </TaskDetailItemEditCard>
</template>

<script>
import TaskDetailItemEditCard from "@/views/planning/forms/TaskDetailItemEditCard.vue"
import routeNames from "@/router/RouteNames";
import { getRequiredRules, getStartsWithRules, getMaxStringSizeRules , getMinStringSizeRules } from "@/shared/utils/inputRulesUtils.js"
import FunctionalIdInput from "../../../components/shared/fields/FunctionalIdInput.vue"

export default {
  name: "TaskDetailEditFunctionalId",
  props: {
    projectId: {
      type: String,
      required: true
    },
    clientId: {
      type: String,
      required: true
    },
    functionalId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      routeNames,
      taskName: null,
      taskId: null,
      promises: [
        this.$store.dispatch('tasksModule/fetchTaskBasic', { clientId: this.clientId, projectId: this.projectId, functionalId: this.functionalId })
          .then((result) => { this.taskName = result.taskName; this.taskId = result.functionalId })
      ],
      lastFetchedFunctionalIdSuggestion: null,
      functionalIdRules: [
        ...getRequiredRules('Id van de taak moet ingevuld worden'),
        ...getStartsWithRules('T-', 'Id van een taak moet beginnen met T-'),
        ...getMinStringSizeRules(3, 'Id van een taak moet minimaal 1 teken bevatten'),
        ...getMaxStringSizeRules(6, 'Id van een taak mag maximaal 4 tekens bevatten')
      ]
    }
  },
  components: {
    TaskDetailItemEditCard,
    FunctionalIdInput,
  },
  methods: {
    getRequiredRules,
    getStartsWithRules,
    getMinStringSizeRules,
    getMaxStringSizeRules,
    fetchGeneratedFunctionalId() {
      if (this.taskName) {
        this.$store.dispatch("tasksModule/getFunctionalIdSuggestions", { clientId: this.clientId, projectId: this.projectId, seed: this.taskName, existingId: this.functionalId })
          .then(result => {
            this.lastFetchedFunctionalIdSuggestion = result
          })
      }
    },
  },
  watch: {
    taskName: {
      deep: true,
      immediate: true,
      handler() {
        if (this.taskName) {
          this.fetchGeneratedFunctionalId()
        }
      }
    },

  }
}
</script>