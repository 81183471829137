import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import CustomStyling from '../styles/build.scss';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {customProperties: true},
        themes: {
            light: {
                'primary': CustomStyling.yankeeBlue,
                'secondary': CustomStyling.arcticBlue,
                'tertiary': '#FFFFFF',
                'body-background': CustomStyling.skyBlue,
                'cultured-gray': CustomStyling.culturedGray,
                'light-gray': CustomStyling.lightGray,
                'grey': CustomStyling.fossilGray,
                'green': CustomStyling.springGreen,
                'anchor': CustomStyling.arcticBlue,
                'nav-text': CustomStyling.cadetGray,
                'nav-text-active': CustomStyling.springGreen,
                'banner-blue': CustomStyling.andromedaBlue,
                'banner-orange': CustomStyling.peachOrange,
                'banner-red': CustomStyling.salmonRed,
                'banner-green': CustomStyling.mintGreen,
                'work-green': CustomStyling.workGreen
            },
        },
        breakpoint: {
            mobileBreakpoint: 'xs'
        }
    }
});