<template>
  <BasicInformationCard title="Herhaling informatie" v-if="showComponent"
      :editRouterLink="{ name: routeNames.TASK_DETAIL_EDIT_TASK_RECURRENCE, params: { clientId: clientId, projectId: projectId, functionalId: functionalId } }"
      :promises.sync="promises">
    <template v-slot:content>
      <v-container>
        <v-row>
          <v-col cols="12" md="7">
            <v-row>
              <v-col cols="12" sm="6">
                <BasicInformationItem description="Herhaling frequentie"
                  :value="getRecurrencePeriodText" />
              </v-col>
              <v-col cols="12" sm="6">
                <BasicInformationItem description="Minimum duratie tussen taken"
                  :value="parseISO8601Duration(recurrenceInformation.recurrenceDetails.minGapBetweenRepetitions).h  + ' uren' " />
              </v-col>
              <v-col cols="12" sm="6">
                <BasicInformationItem description="Maximum duratie tussen taken"
                  :value="parseISO8601Duration(recurrenceInformation.recurrenceDetails.maxGapBetweenRepetitions).h  + ' uren' " />
              </v-col>
              <v-col cols="12" sm="6">
                <BasicInformationItem description="Aantal keer per dag"
                  :value="recurrenceInformation.recurrenceDetails.timesPerDay" />
              </v-col>
              <v-col cols="12" sm="6">
                <BasicInformationItem description="Aantal keer per dag"
                  :value="translateBooleanToYesOrNo(recurrenceInformation.recurrenceDetails.sameDayForRecurrentTasks)" />
              </v-col>
              <v-col cols="12" sm="6">
                <BasicInformationItem description="Aantal keer per dag"
                  :value="translateBooleanToYesOrNo(recurrenceInformation.recurrenceDetails.samePersonForRecurrentTasks)" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </BasicInformationCard>
</template>

<script>
import BasicInformationCard from '../shared/BasicInformationCard.vue'
import BasicInformationItem from '../shared/BasicInformationItem.vue'
import routeNames from "@/router/RouteNames"
import { applyDateFormat } from "@/shared/utils/dateUtils"
import { translateTaskFrequency, translateTimeUnit, translateBooleanToYesOrNo, translateRecurrencePeriodType } from "@/shared/utils/translateUtils"
import { parseISO8601Duration } from "@/shared/utils/timeUtils"
import TaskFrequencyType from '../../shared/enums/taskFrequencyType'

export default {
  name: "TaskRecurrenceInformationComponent",
  props: {
    clientId: {
      type: String,
      required: true
    },
    projectId: {
      type: String,
      required: true
    },
    functionalId: {
      type: String,
      required: true
    }
  },
  components: {
    BasicInformationCard,
    BasicInformationItem,
  },
  data() {
    return {
      routeNames,
      TaskFrequencyType,
      translateBooleanToYesOrNo,
      recurrenceInformation: {
          taskFrequency: null,
          recurrenceDetails: {
            recurrencePeriod: 1,
            recurrencePeriodType: null,
            timesPerDay: 1,
            minGapBetweenRepetitions: null,
            maxGapBetweenRepetitions: null,
            samePersonForRecurrentTasks: false,
            sameDayForRecurrentTasks: false,
          },
      },
      promises: [
        this.getTaskInformation()
      ]
    }
  },
  methods: {
      applyDateFormat,
      translateTaskFrequency,
      translateTimeUnit,
      parseISO8601Duration,
      translateRecurrencePeriodType,
      getTaskInformation() {
        return this.$store.dispatch("tasksModule/fetchTaskRecurrence", { clientId: this.clientId, projectId: this.projectId, functionalId: this.functionalId })
          .then(result => { 
            this.recurrenceInformation = result
          })
      }
  },
  computed: {
    showComponent() {
      return this.recurrenceInformation.taskFrequency === TaskFrequencyType.RECURRENT
    },
    getRecurrencePeriodText() {
      if (this.recurrenceInformation.recurrenceDetails.recurrencePeriod === 1) {
        return 'Iedere ' + this.translateRecurrencePeriodType(this.recurrenceInformation.recurrenceDetails.recurrencePeriodType)
      }
      return "Om de " + this.recurrenceInformation.recurrenceDetails.recurrencePeriod + ' ' + this.translateRecurrencePeriodType(this.recurrenceInformation.recurrenceDetails.recurrencePeriodType, true)
    }
  }
}
</script>