<template>
  <div>
    <ProjectDetailItemEditCard confirmation-message="Project informatie werd succesvol gewijzigd"
      title="Bewerk project informatie" :projectId="projectId" :clientId="clientId" 
      update-store-action="projectsModule/updateProjectInformation" :update-store-action-payload="{clientId: clientId, projectId: projectId, projectInformation: projectInformation}"
      detail-view-route-location=""
      >
      <template v-slot:form-fields>
        <v-row>
          <v-col cols="12" md="6">
            <DatePicker v-model="projectInformation.startDate" label="Start datum" required :default-now="false"/>
          </v-col>
          <v-col cols="12" md="6">
            <DatePicker v-model="projectInformation.endDate" label="Eind datum" :min="new Date(projectInformation.startDate)" :default-now="false"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-if="isCIAOEnabled" v-model="projectInformation.declarationOfWork" persistent-placeholder label="Aangifte van werken" type="text"/>
          </v-col>
          <v-col cols="12">
            <v-subheader class="text-h5 pl-0"><v-icon class="pr-2">mdi-map-marker</v-icon> Adres</v-subheader>
            <AddressInput v-model="projectInformation.address"/>
          </v-col>
        </v-row>
      </template>
    </ProjectDetailItemEditCard>
  </div>
</template>

<script>
import ProjectDetailItemEditCard from "@/views/project/forms/ProjectDetailItemEditCard.vue"
import DatePicker from "@/components/shared/fields/DatePicker.vue"
import AddressInput from "../../../components/shared/fields/AddressInput.vue"
import {getRequiredRules} from "@/shared/utils/inputRulesUtils.js"

export default {
  name: "ProjectDetailEditProjectInformation",
  props: {
    projectId: {
      type: String,
      required: true
    },
    clientId: {
      type: String,
      required: true
    }
  },
  components: {
    ProjectDetailItemEditCard,
    DatePicker,
    AddressInput
  },
  data() {
    return {
      projectInformation: {
        startDate: null,
        endDate: null,
        address: {},
        declarationOfWork: null
      },
      promises: [
          this.$store.dispatch('projectsModule/fetchProjectInformation', {clientId: this.clientId, projectId: this.projectId})
            .then((projectInformation) => {
              this.projectInformation = projectInformation
              if (!this.projectInformation.address) {
                  this.projectInformation.address = {}
              }
          }),
          this.$store.dispatch('locationRegistrationConfigModule/fetchLocationConfig')
        ]
    }
  },
  computed: {
    isCIAOEnabled() {
      return this.$store.getters["locationRegistrationConfigModule/getLocationConfig"]?.ciaoConfig?.ciaoEnabled === true
    },
  },
  methods: {
    getRequiredRules
  }
}
</script>
