<template>
    <SuborganizationDetailItemEditCard confirmation-message="Suborganisatie naam werd succesvol gewijzigd"
                      title="Bewerk suborganisatie naam"
                      update-store-action="suborganizationModule/updateSuborganizationName" :update-store-action-payload="{name: suborganizationName, organizationId, suborganizationId}" 
                      :organizationId="organizationId" :suborganizationId="suborganizationId" :promises.sync="promises">
        <template v-slot:form-fields>
            <v-row>
                <v-col cols="12" md="6">
                <v-text-field v-model="suborganizationName" persistent-placeholder label="Naam" type="text" />
                </v-col>
            </v-row>
        </template>
    </SuborganizationDetailItemEditCard>
</template>

<script>
import SuborganizationDetailItemEditCard from "@/views/suborganization/forms/SuborganizationDetailItemEditCard.vue"


export default {
  name: "SuborganizationDetailEditTitle",
  props: {
    organizationId: {
      type: String,
      required: true
    },
    suborganizationId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      suborganizationName: null,
        promises: [
          this.$store.dispatch('suborganizationModule/fetchSuborganizationName', {organizationId: this.organizationId, suborganizationId: this.suborganizationId})
            .then((suborganizationName) => this.suborganizationName = suborganizationName)
        ]
    }
  },
  components: {
    SuborganizationDetailItemEditCard
  },
}
</script>