<template>
    <PromiseContainer :promises.sync="promises">
        <v-row>
            <v-col cols="12" class="d-flex justify-space-between align-center">
                <v-subheader class="text-h5 pl-0"><v-icon class="pr-2">mdi-briefcase-outline</v-icon> Suborganisaties</v-subheader>
                <v-btn class="secondary text-none" :to="newSubOrganizationRoute">
                    <v-icon left>mdi-plus</v-icon> Nieuwe suborganizatie
                </v-btn>
            </v-col>
            <v-col :id="suborganization.id" v-for="suborganization in suborganizations" :key="suborganization.id" cols="12" lg="7" >
                <v-row>
                    <v-col cols="12">
                        <SuborganizationDetailHeaderComponent :organizationId="organizationId" :suborganizationId="suborganization.id" :suborganizationName="suborganization.name" :on-change="fetchAllSuborganizations"/>
                    </v-col>
                    <v-col cols="12" class="pb-8">
                        <SuborganizationInformationComponent :suborganizationInformation="suborganization"/>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </PromiseContainer>
</template>
<script>
import PromiseContainer from '@/components/shared/PromiseContainer.vue';
import routeNames from "@/router/RouteNames";
import SuborganizationInformationComponent from '@/components/suborganization/SuborganizationInformationComponent.vue';
import SuborganizationDetailHeaderComponent from '@/components/suborganization/SuborganizationDetailHeaderComponent.vue';
export default {
    name: "SuborganizationsList",
    components: {
        PromiseContainer,
        SuborganizationInformationComponent,
        SuborganizationDetailHeaderComponent
    },
    props: {
        organizationId: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            suborganizations: [],
            promises: [],
            newSubOrganizationRoute: { name: routeNames.SETTINGS_SUBORGANIZATION_CREATE, params: { organizationId: this.organizationId } }
        }
    },
    created() {
        this.fetchAllSuborganizations()
    },
    methods: {
        fetchAllSuborganizations() {
            this.promises.push(this.$store.dispatch("suborganizationModule/fetchAllSuborganizations", this.organizationId)
                    .then(async result =>  {
                        this.suborganizations = result.map(suborganization => ({...suborganization, organizationId: this.organizationId}))
                    await this.$nextTick()
                }))
        }
    },
}
</script>