<template>
    <span>
        <slot></slot>
        <router-link v-if="route" :to="route" class="text-decoration-none clickable">
            <v-icon dense class="vertical-align-baseline">mdi-pencil</v-icon>
        </router-link>
    </span>
</template>

<script>
export default {
    name: "Editable",
    props: {
        route: null,
    }
}
</script>