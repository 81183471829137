<template>
    <div>
        <InvoiceEditCard :confirmation-message="getConfirmationMessage" :title="getFormTitle"
            :update-store-action="getFormAction" :update-store-action-payload="getInvoiceOpenPayload"
            :invoiceId="invoiceId" :promises.sync="promises" :success-callback="invoiceCreatedSuccessCallback">
            <template v-slot:after-header v-if="showActionMenu">
                <v-col cols="auto" class="flex-grow-0">
                    <v-chip :color="getInvoiceStatusTypeColor(invoiceDTO.statusType)" label large class="max-h-36px">
                        {{ translateInvoiceStatusType(invoiceDTO.statusType) }}
                    </v-chip>
                </v-col>
                <v-col cols="auto" class="flex-grow-0">
                    <v-menu offset-y left bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn outlined class="text-none" color="secondary" v-bind="attrs" v-on="on">
                                Acties
                                <v-icon left class="ma-0">mdi-chevron-down</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item link @click="onInvoiceCopyClick">
                                <v-list-item-icon>
                                    <v-icon>mdi-content-copy</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Dupliceer</v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="showDeleteButton" link @click="onDeleteClientClick">
                                <v-list-item-icon>
                                    <v-icon>mdi-delete</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Verwijder</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-col>
            </template>
            <template v-slot:form-fields>
                <v-subheader class="text-h5 pl-0"><v-icon
                        class="pr-2">mdi-invoice-edit-outline</v-icon>Algemeen</v-subheader>
                <template v-if="showManualClientInputs">
                    <v-subheader class="text-h6 pl-0"><v-icon class="pr-2">mdi-account-box</v-icon>Klant</v-subheader>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-text-field v-model="clientData.clientName" required persistent-placeholder
                                label="Klantnaam" :rules="getRequiredRules('Klantnaam moet ingevuld worden')" />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field v-model="clientData.clientVat" persistent-placeholder
                                label="Klant btw nummer" />
                        </v-col>
                        <v-col cols="12">
                            <AddressInput v-model="clientData.clientAddress" required />
                        </v-col>
                    </v-row>
                </template>
                <v-subheader v-if="showManualClientInputs" class="text-h6 pl-0"><v-icon
                        class="pr-2">mdi-invoice-text-outline</v-icon>Factuur</v-subheader>
                <v-row>
                    <v-col v-if="!showManualClientInputs" cols="12" md="6">
                        <FetchOptionsSelect required persistent-placeholder label="Klant"
                            action="clientsModule/fetchNotArchivedClients" item-value="id" item-text="clientName"
                            v-model="invoiceDTO.clientId" :rules="getRequiredRules('Klant moet geselecteerd worden')"
                            noTranslate />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field readonly persistent-hint label="Factuurnummer" type="text"
                            :value="getInvoiceNumber" :hint="getHint"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <DatePicker required label="Datum" v-model="invoiceDTO.invoiceDate" />
                    </v-col>
                    <v-col v-if="showIsPaid" cols="12" md="6">
                        <v-label for="isPaid" class="v-label v-label--active font-size-12">Reeds
                            betaald</v-label>
                        <v-switch id="isPaid" dense class="d-flex justify-end mt-2" hide-details="auto"
                            v-model="invoiceDTO.isPaid" />
                    </v-col>
                    <v-col v-if="showExpirationTime" cols="12" md="6">
                        <v-text-field :required="!invoiceDTO.isPaid" :disabled="invoiceDTO.isPaid"
                            persistent-placeholder label="Betalingstermijn (dagen)" type="number"
                            v-model="invoiceDTO.daysToPay"
                            :rules="invoiceDTO.isPaid ? [] : getRequiredRules('Betalingstermijn moet ingevuld worden')"></v-text-field>
                    </v-col>
                    <v-col v-if="showExpirationTime" cols="12" md="6">
                        <v-text-field readonly :disabled="invoiceDTO.isPaid" label="Vervaldatum"
                            :value="applyDateFormat(getExpirationDate)" />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-label for="vatShiftCoContractor" class="v-label v-label--active font-size-12">BTW verlegd aan
                            medecontractant</v-label>
                        <v-switch id="vatShiftCoContractor" dense class="d-flex justify-end mt-2" hide-details="auto"
                            v-model="invoiceDTO.vatShiftCoContractor" />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-textarea class="custom-textarea"  outlined label="Extra mededeling" clearable clear-icon="mdi-close-circle"
                            :rules="[v => (v == null || v.length <= 200) || 'Opmerking kan maximaal uit 200 karakters bestaan']"
                            v-model="invoiceDTO.comment" />
                    </v-col>
                </v-row>
                <v-subheader class="text-h5 pl-0"><v-icon
                        class="pr-2">mdi-invoice-text-clock-outline</v-icon>Berekening</v-subheader>
                <v-row>
                    <v-col cols="12" md="6">
                        <DatePicker label="Begin facturatieperiode" v-model="invoiceDTO.invoicePeriodFrom" required />
                    </v-col>
                    <v-col cols="12" md="6">
                        <DatePicker label="Einde facturatieperiode" v-model="invoiceDTO.invoicePeriodUntil" required
                            :min="invoiceDTO.invoicePeriodFrom ? new Date(invoiceDTO.invoicePeriodFrom) : null" />
                    </v-col>
                </v-row>
                <div v-for="(clientInvoiceLine, index) in clientInvoiceLines" :key="index">

                    <ClientInvoiceLine v-if="clientInvoiceLine.type === InvoiceLineType.WORKING_HOURS"
                        :on-remove="() => removeClientInvoiceLine(index)" :value="clientInvoiceLine"
                        :invoicePeriodFrom="invoiceDTO.invoicePeriodFrom"
                        :invoicePeriodUntil="invoiceDTO.invoicePeriodUntil" />

                    <ClientInvoiceLineDescription v-if="clientInvoiceLine.type === InvoiceLineType.DESCRIPTION"
                        :on-remove="() => removeClientInvoiceLine(index)" :value="clientInvoiceLine"
                        :invoicePeriodFrom="invoiceDTO.invoicePeriodFrom"
                        :invoicePeriodUntil="invoiceDTO.invoicePeriodUntil" />

                </div>
                <v-row>
                    <v-col>
                        <v-divider />
                    </v-col>
                </v-row>
                <v-container id="dropdown-invoiceline-type">
                    <v-col cols="12" md="6">
                        <v-overflow-btn solo :items="getInvoiceLineOptions" label="Voeg factuurlijn toe" segmented
                            target="#dropdown-invoiceline-type"></v-overflow-btn>
                    </v-col>
                </v-container>

                <v-row>
                    <v-col v-if="invoiceDTO.blobStorageUrl && getSASKey" cols="12" md="6">
                        <v-sheet flat outlined>
                            <v-toolbar flat>
                                <v-btn icon @click="$refs.overlay.toggle()">
                                    <v-icon>mdi-text-box-search-outline</v-icon>
                                </v-btn>
                                <v-btn icon @click="$refs.pdf.download(invoiceDTO.invoiceNumber)">
                                    <v-icon>mdi-download</v-icon>
                                </v-btn>
                            </v-toolbar>
                            <v-divider></v-divider>
                            <vue-pdf-embed ref="pdf" :source="getPDfSource" />
                        </v-sheet>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:buttons-left="slotProps">
                <v-col v-if="showDraftButton" cols="auto" class="flex-grow-1" align="end">
                    <v-btn color="secondary" :disabled="!slotProps.valid" @click="onDraftInvoiceClick()">Ontwerp</v-btn>
                </v-col>
            </template>
        </InvoiceEditCard>
        <Overlay ref="overlay"><vue-pdf-embed class="max-h-100vh max-w-100vw" :source="getPDfSource" /></Overlay>
    </div>
</template>
<script>
import FetchOptionsSelect from "@/components/shared/fields/FetchOptionsSelect.vue";
import InvoiceEditCard from "@/views/invoice/InvoiceEditCard.vue";
import { getRequiredRules } from '@/shared/utils/inputRulesUtils.js';
import DatePicker from "@/components/shared/fields/DatePicker.vue";
import ClientInvoiceLine from "@/components/invoice/ClientInvoiceLine.vue";
import ClientInvoiceLineDescription from "@/components/invoice/ClientInvoiceLineDescription.vue";
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import Overlay from "@/components/shared/overlays/Overlay.vue";
import { format } from "date-fns";
import { applyDateFormat } from "@/shared/utils/dateUtils";
import InvoiceLineType from "@/shared/enums/invoiceLineType";
import ModuleType from "@/shared/enums/moduleType";
import AddressInput from "@/components/shared/fields/AddressInput.vue";
import InvoiceStatusType from "@/shared/enums/invoiceStatusType";
import RouteNames from "@/router/RouteNames";
import { translateInvoiceStatusType, getInvoiceStatusTypeColor } from "@/shared/utils/translateUtils";
import store from "@/store/index";

const VUEJS_DATE_FORMAT = "yyyy-MM-dd";

export default {
    name: "InvoiceEdit",
    props: {
        invoiceId: {
            type: String,
            required: false,
        },
        originalInvoiceId: {
            type: String,
            required: false
        },
        credit: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            RouteNames,
            invoiceDTO: {
                name: "factuur",
                clientId: null,
                invoiceNumber: null,
                invoiceDate: null,
                daysToPay: null,
                invoicePeriodFrom: null,
                invoicePeriodUntil: null,
                expirationDate: null,
                statusType: InvoiceStatusType.DRAFT,
                isPaid: false,
                vatShiftCoContractor: false,
                comment: null
            },
            clientData: {
                clientName: null,
                clientAddress: {}
            },
            InvoiceLineType: InvoiceLineType,
            sasKey: null,
            promises: [this.$store.dispatch("fileExportModule/fetchSASKey").then(result => this.sasKey = result), this.fetchExistingInvoice(), this.fetchNextNumbers()],
            newClientInvoiceLine: {},
            clientInvoiceLines: [],
            nextNumbers: {
                invoice: null,
                draft: null
            }
        }
    },
    methods: {
        translateInvoiceStatusType,
        getInvoiceStatusTypeColor,
        getRequiredRules,
        applyDateFormat,
        setInvoiceDTO(data) {
            this.invoiceDTO = {
                ...this.invoiceDTO, ...data,
                clientInvoiceLines: null,
            };
            this.clientInvoiceLines = data.clientInvoiceLines
        },
        setClientData(data) {
            this.clientData = {
                clientName: data?.invoiceOrganization?.name,
                clientVat: data?.invoiceOrganization?.vat,
                clientAddress: data?.invoiceOrganization?.organizationAddress,
            }
        },
        addClientInvoiceLine(type) {
            const newInvoiceLine = {
                type: type,
                invoicePeriodFrom: this.invoiceDTO.invoicePeriodFrom,
                invoicePeriodUntil: this.invoiceDTO.invoicePeriodUntil
            }
            this.clientInvoiceLines.push({ ...newInvoiceLine })
        },
        removeClientInvoiceLine(index) {
            this.clientInvoiceLines.splice(index, 1)
        },
        addDays(date, days) {
            var result = new Date(date);
            result.setDate(result.getDate() + parseInt(days));
            return format(result, VUEJS_DATE_FORMAT);
        },
        onDraftInvoiceClick() {
            this.promises.push(
                this.$store.dispatch(this.getFormAction, this.getInvoiceDraftPayload)
                    .then(
                        invoiceDTOResponse => {
                            store.dispatch('setSuccessMessage', { message: 'Ontwerp van factuur werd opgeslagen' })
                            if (this.invoiceId) {
                                this.promises.push(this.fetchExistingInvoice())
                            } else {
                                this.$router.push({ name: RouteNames.INVOICE_EDIT, params: { invoiceId: invoiceDTOResponse.id } })
                            }
                        }
                    )
            )
        },
        invoiceCreatedSuccessCallback(invoice) {
            this.$router.push({ name: RouteNames.INVOICE_DETAIL, params: { invoiceId: invoice.id } })
        },
        fetchExistingInvoice() {
            if (this.invoiceId) {
                return this.$store.dispatch("invoicesModule/fetchDraftInvoice", this.invoiceId)
                    .then(invoiceDraftDTO => {
                        this.setInvoiceDTO(invoiceDraftDTO)
                        this.setClientData(invoiceDraftDTO)
                    })
            } else if (this.credit && this.originalInvoiceId) {
                return this.$store.dispatch("invoicesModule/fetchCreditNote", this.originalInvoiceId)
                    .then(creditNotaDTO => {
                        this.setInvoiceDTO(creditNotaDTO)
                        this.setClientData(creditNotaDTO)
                    })
            } else if (this.originalInvoiceId) {
                return this.$store.dispatch("invoicesModule/fetchInvoiceDuplicate", this.originalInvoiceId)
                    .then(invoiceDraftDTO => {
                        this.setInvoiceDTO(invoiceDraftDTO)
                        this.setClientData(invoiceDraftDTO)
                    })
            }
        },
        fetchNextNumbers() {

            return this.$store.dispatch("invoicesModule/fetchNextNumbers")
                .then(
                    nextNumbers => {
                        this.nextNumbers.invoice = nextNumbers.nextInvoice;
                        this.nextNumbers.draft = nextNumbers.nextDraft;
                    }
                )
        },
        onDeleteClientClick() {
            this.$store.dispatch("invoicesModule/deleteInvoice", this.invoiceId)
                .then(() => this.$router.push({ name: RouteNames.INVOICE_OVERVIEW }))
        },
        onInvoiceCopyClick() {
            this.$router.push({ name: RouteNames.INVOICE_CREATE, query: { originalInvoiceId: this.invoiceId } })
        },
    },
    components: {
        FetchOptionsSelect,
        InvoiceEditCard,
        DatePicker,
        ClientInvoiceLine,
        ClientInvoiceLineDescription,
        VuePdfEmbed,
        Overlay,
        AddressInput
    },
    computed: {
        getConfirmationMessage() {
            if (this.credit) {
                return "Creditnota werd aangemaakt"
            } else {
                const status = this.invoiceDTO.isPaid ? "betaald" : "open";
                return `Factuur werd op ${status} gezet`;
            }
        },
        getFormTitle() {
            if (this.invoiceId) {
                return "Bewerk factuur"
            } else if (this.credit) {
                return "Creditnota maken"
            } else {
                return "Factuur maken"
            }
        },
        getFormAction() {
            if (this.invoiceId) {
                return "invoicesModule/updateInvoice"
            } else {
                return "invoicesModule/createInvoice"
            }
        },
        showManualClientInputs() {
            return !this.isClientProjectManagementModulePresent || (this.invoiceId || this.originalInvoiceId)
        },
        isClientProjectManagementModulePresent() {
            return this.$store.getters["organizationModule/organizationHasModule"]([ModuleType.CLIENT_PROJECTMANAGEMENT])
        },
        getPDfSource() {
            if (this.getSASKey && this.invoiceDTO.blobStorageUrl) {
                return this.invoiceDTO.blobStorageUrl + '?timeStopCache=' + Date.now() + '&' + this.getSASKey
            }
            return null
        },
        getSASKey() {
            return this.$store.getters["fileExportModule/getSASKey"] || this.sasKey
        },
        getInvoiceOpenPayload() {
            let dto = {
                ...this.invoiceDTO,
                ...this.clientData,
                createClientInvoiceLineDTOS: this.clientInvoiceLines,
                expirationDate: this.getExpirationDate,
                statusType: this.invoiceDTO.isPaid ? InvoiceStatusType.PAID : InvoiceStatusType.OPEN
            }

            if (this.invoiceId) {
                return { invoiceId: this.invoiceId, invoiceDTO: dto }
            } else {
                return dto
            }
        },
        getInvoiceDraftPayload() {
            let dto = {
                ...this.invoiceDTO,
                ...this.clientData,
                createClientInvoiceLineDTOS: this.clientInvoiceLines,
                expirationDate: this.getExpirationDate,
                statusType: InvoiceStatusType.DRAFT
            }

            if (this.invoiceId) {
                return { invoiceId: this.invoiceId, invoiceDTO: dto }
            } else {
                return dto
            }
        },
        getExpirationDate() {
            if (this.invoiceDTO?.daysToPay !== null && this.invoiceDTO.invoiceDate) {
                return this.addDays(this.invoiceDTO.invoiceDate, this.invoiceDTO.daysToPay)
            }
            return null

        },
        getInvoiceLineOptions() {
            let invoiceLineOptions = [{ text: 'Voeg lijn toe', callback: () => this.addClientInvoiceLine(InvoiceLineType.DESCRIPTION) }]

            if (this.isClientProjectManagementModulePresent) {
                invoiceLineOptions.unshift({ text: 'Voeg werkuren toe', callback: () => this.addClientInvoiceLine(InvoiceLineType.WORKING_HOURS) })
            }

            return invoiceLineOptions
        },
        showDraftButton() {
            return !this.invoiceDTO?.credit
        },
        showExpirationTime() {
            return !this.invoiceDTO?.credit
        },
        showIsPaid() {
            return !this.invoiceDTO?.credit
        },
        showDeleteButton() {
            return this.invoiceDTO?.statusType == InvoiceStatusType.DRAFT
        },
        showActionMenu() {
            // action menu not usefull when invoice still has to be created
            return this.$route.name != RouteNames.INVOICE_CREATE
        },
        getHint() {
            return "Bij creatie wordt factuurnummer op " + this.nextNumbers.invoice + " gezet.";
        },
        getInvoiceNumber() {
            return this.invoiceId ? this.invoiceDTO.invoiceNumber : this.nextNumbers.draft;
        }

    }
}
</script>

<style>
.vue-pdf-embed canvas {
    width: 100% !important;
    height: auto !important;
}
/* to centralize the arrow of the selection box (factuurlijnen toevoegen) */
.v-overflow-btn.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)>.v-input__control>.v-input__slot {
    padding: 0px;
}
</style>