<template>
  <v-row>
    <v-col cols="10">
      <v-text-field v-model="computedValue" v-bind="$attrs" v-on="$listeners"
        persistent-placeholder 
        type="text" 
        @input="validateNewId" 
        />
    </v-col>
    <v-col cols="2" class="d-flex justify-center align-center">
      <v-icon v-if="showGenerateFunctionalIdButton" @click="resetToSuggestion()"
        :title="`Gebruik de automatische id: ${suggestion}`" color="red">
        mdi-undo
      </v-icon>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: "FunctionalIdInput",
  props: {
    value: {
      required: true
    },
    suggestion: {
      required: true
    },
    prefix: {
      type: String,
      required: true
    },
  },
  methods: {
    resetToSuggestion() {
      this.$emit('input', this.suggestion)
      this.$emit('update:value', this.suggestion)
    },
    validateNewId(input) {
      let output = input

      this.$nextTick(() => {
        // Prevent external overrides by using `$nextTick` ( make sure Vue has updated the DOM)
        if (!input || input.trim() === "") {
          output = this.prefix
        } else {
          input = input.toUpperCase()
          if (!input.startsWith(this.prefix)) {
            // If the prefix is missing, enforce it
            output = this.prefix + input.replace(new RegExp(`^${this.prefix}*`), "")
          } else {
            // Otherwise, keep the valid input
            output = input
          }
        }

        this.$emit('input', output)         // BB-1104 vue upgrade, check wich event is needed
        this.$emit('update:value', output)
      })
    }
  },
  computed: {
    showGenerateFunctionalIdButton() {
      return this.suggestion !== this.value
    },
    computedValue: {
      get() {
        // Return the prop value
        return this.value
      },
      set(newValue) {
        // Emit the updated value back to the parent
        this.$emit('input', newValue)
        this.$emit('update:value', newValue)
      }
    },
  },
}
</script>