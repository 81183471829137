<template>
  <PromiseContainer :promises.sync="promises">
    <overview :items="tasks" :headers="headers" :filters="filters" header-text="Taken" add-btn-text="Nieuwe taak"
      :add-item-component="pushToCreateRoute" :detail-page-function="detailPageFunction">
      <template v-slot:item.taskStatus="{ item }">
        <v-chip :color="getTaskStatusColor(item.taskStatus)" label class="max-h-36px">
          {{ translateTaskStatus(item.taskStatus) }}
        </v-chip>
      </template>
    </overview>
  </PromiseContainer>
</template>

<script>
import Overview from "@/components/shared/Overview";
import RouteNames from "@/router/RouteNames";
import PromiseContainer from "@/components/shared/PromiseContainer.vue"
import { translateTaskStatus, getTaskStatusColor } from "@/shared/utils/translateUtils";

export default {
  name: "TaskOverview.vue",
  components: {
    PromiseContainer,
    Overview
  },
  data() {
    return {
      headers: [
        {
          text: "Id",
          value: 'functionalId',
          cellClass: 'notranslate'
        },
        {
          text: "Naam",
          value: 'taskName',
          cellClass: 'notranslate'
        },
        {
          text: "Klant",
          value: 'client.clientName',
          cellClass: 'notranslate'
        },
        {
          text: "Project",
          value: 'project.projectName',
          cellClass: 'notranslate'
        },
        {
          text: 'Status',
          value: 'taskStatus'
        }
      ],
      promises: [this.fetchTasks()],
      filters: [
        {
          title: "Status",
          options: [
            {
              id: 'task#status#active',
              label: 'Actief',
              active: false,
              predicate: (task) => task.taskStatus && task.taskStatus === "ACTIVE"
            },
            {
              id: 'task#status#cancelled',
              label: 'Geannuleerd',
              active: false,
              predicate: (task) => task.taskStatus && task.taskStatus === "CANCELLED"
            },
            {
              id: 'task#status#done',
              label: 'Gedaan',
              active: false,
              predicate: (task) => task.taskStatus && task.taskStatus === "DONE"
            },
          ]
        }
      ],
      tasks: [],
      detailPageFunction: (task) => {
        return {
          name: RouteNames.TASK_DETAIL,
          params: {
            projectId: task.project.id,
            clientId: task.client.id,
            functionalId: task.functionalId,
            returnToTaskOverview: true
          }
        }
      },
    }
  },
  methods: {
    translateTaskStatus,
    getTaskStatusColor,
    pushToCreateRoute() {
      this.$router.push({ name: RouteNames.TASK_OVERVIEW_TASK_CREATE })
    },
    fetchTasks() {
    return this.$store.dispatch("tasksModule/fetchNotArchivedTasks")
        .then((data) => {
          this.tasks = data
          this.updateFilters()
        })
    },
    updateFilters() {
      if (this.getFilterClientOptions.length > 0) {
        this.filters.push({
          title: 'Klant',
          options: this.getFilterClientOptions
        })
      }
      if (this.getFilterProjectOptions.length > 0) {
        this.filters.push({
          title: 'Project',
          options: this.getFilterProjectOptions
        })
      }
    }
  },
  computed: {
    getFilterClientOptions() {
      const clientNames = [...new Set(this.tasks.filter(task => task.client).map(task => task.client.clientName))];
      return clientNames.map(clientName => {
        return {
          id: 'task#client#' + clientName,
          label: clientName,
          active: false,
          predicate: (task) => task.client && task.client.clientName === clientName
        }
      }).sort((a, b) => a.label.localeCompare(b.label))
    },
 
  getFilterProjectOptions() {
      const projectNames = [...new Set(this.tasks.filter(task => task.project).map(task => task.project.projectName))];
      return projectNames.map(projectName => {
        return {
          id: 'task#project#' + projectName,
          label: projectName,
          active: false,
          predicate: (task) => task.client && task.project.projectName === projectName
        }
      }).sort((a, b) => a.label.localeCompare(b.label))
    }
  },
}
</script>