import axiosInstance, {addPlainTextHeader} from "@/store/axios.instance";


export default {
    namespaced: true,
    state() {
        return {
            // TODO replace organization with a dto for creating organization (make it more specific)
            organization: null,
            address: null,
            modules: [],
            activeSubscription: null,
            trial: null,
            subDaysLeft: null,
            organizationLogo: null,
        }
    },
    mutations: {
        setOrganization(state, organization) {
            state.organization = organization
        },
        setAddress(state, address) {
            state.address = address
        },
        setSubscriptionStatus(state, subscriptionStatus) {
            state.trial = subscriptionStatus.trial
            state.subDaysLeft = subscriptionStatus.subDaysLeft
        },
        clearSubscriptionStatus(state) {
            state.trial = null
            state.subDaysLeft = null
        },
        setActiveSubscription(state, activeSubscription) {
            state.activeSubscription = activeSubscription
        },
        setModules(state, modules){
            state.modules = modules
        },
        setOrganizationLogo(state,data) {
            state.organizationLogo = data.logo
        }
    },
    actions: {
        fetchOrganization({commit, rootGetters}) {
            return axiosInstance.get('organization', rootGetters["authModule/getUrlConfig"])
                .then(result => commit('setOrganization', result?.data))
        },
        updateOrganizationName({rootGetters}, payload) {
            return axiosInstance.put(`organization/${payload.organizationId}/organization-name`, payload.organizationName, addPlainTextHeader(rootGetters["authModule/getUrlConfig"]))
                .then(result => result?.data)
        },
        fetchOrganizationName({rootGetters}, organizationId) {
            return axiosInstance.get(`organization/${organizationId}/organization-name`, rootGetters["authModule/getUrlConfig"])
                .then(result => result?.data)
        },
        fetchOrganizationInformation({rootGetters}, organizationId) {
            return axiosInstance.get(`organization/${organizationId}/organization-information`, rootGetters["authModule/getUrlConfig"])
                .then(result => result?.data)
        },
        updateOrganizationInformation({rootGetters}, payload) {
            return axiosInstance.put(`organization/${payload.organizationId}/organization-information`, payload.organizationInformation, rootGetters["authModule/getUrlConfig"])
                .then(result => result?.data)
        },
        fetchAddress({commit, rootGetters}) {
            return axiosInstance.get('organization/' + rootGetters["authModule/getOrganizationId"] + '/address', rootGetters["authModule/getUrlConfig"])
                .then(result => {
                    commit('setAddress', result?.data)
                    return result?.data
                })
        },
        fetchSubscriptionStatus({commit, rootGetters}) {
            return axiosInstance.get('organization/' + rootGetters["authModule/getOrganizationId"] + '/subscription/status', rootGetters["authModule/getUrlConfig"])
                .then(result => {
                    if (result) {
                        commit('setSubscriptionStatus', result?.data)
                        return result?.data
                    } else {
                        commit('clearSubscriptionStatus')
                        return null
                    }
                })
        },
        fetchActiveSubscriptionInfo({commit, rootGetters}) {
            return axiosInstance.get('organization/' + rootGetters["authModule/getOrganizationId"] + '/subscription/active', rootGetters["authModule/getUrlConfig"])
                .then(result => {
                    commit('setActiveSubscription', result?.data)
                    return result?.data
                })
        },
        fetchSubscriptionInfo({rootGetters}, subscriptionId) {
            return axiosInstance.get('organization/' + rootGetters["authModule/getOrganizationId"] + `/subscription/${subscriptionId}`, rootGetters["authModule/getUrlConfig"])
                .then(result => {
                    return result?.data
                })
        },
        fetchBasicSubscriptionHistory({rootGetters}) {
            return axiosInstance.get('organization/' + rootGetters["authModule/getOrganizationId"] + '/subscriptions/basic', rootGetters["authModule/getUrlConfig"])
                .then(result => {
                    return result?.data
                })
        },
        fetchModulesFromOrganization({commit, rootGetters}) {
                return axiosInstance.get('organization/subscription/modules', rootGetters['authModule/getUrlConfig'])
                    .then(result => {
                        commit('setModules',result?.data)
                    })
        },
        fetchOrganizationLogo({commit,rootGetters}){
            return axiosInstance.get('/organization/logo',rootGetters['authModule/getUrlConfig'])
                .then(result => {
                    if(result?.data){
                        commit ('setOrganizationLogo', {logo: result?.data + '?timeStopCache='+ Date.now()})
                        return result?.data + '?timeStopCache='+ Date.now()
                    } else {
                        commit ('setOrganizationLogo', {logo: null})
                        return null
                    }
                })
        },
        updateOrganizationLogo({commit,rootGetters}, data){
            let logo = new FormData();
            logo.append('file',data.image);
            return axiosInstance.post('/organization/logo', logo , rootGetters['authModule/getUrlConfig'])
                .then((result) =>{
                    if(result?.data.startsWith("https")){
                        commit ('setOrganizationLogo', {logo: result?.data + '?timeStopCache='+ Date.now()})
                        return result?.data + '?timeStopCache='+ Date.now()
                    } else {
                        commit ('setOrganizationLogo', {logo: null})
                        return null
                    }})
        }
    },
    getters: {
        getSeats(state) {
            return state.organization.seats
        },
        getModules(state) {
            return state.modules;
        },
        hasTrialOrExpiringSubscription(state){
            return state.trial || state.subDaysLeft < 7
        },
        getOrganizationLogo: (state) => () => {
            return state.organizationLogo
        },
        organizationHasModule: (state) => (allowedModules) => {
            // when no modules are defined, no authorization is being applied on the route
            return state.modules.some(module => allowedModules.includes(module)) || false
        }
    },
}