<template>
  <v-tooltip
    z-index="2000"
    top
  >
    <template #activator="{ on }">
      <v-icon
        :color="color"
        x-small
        class="pb-2"
        v-on="on"
      >
        {{ icon }}
      </v-icon>
    </template>
    <span>{{description}}</span>
  </v-tooltip>
</template>

<script>

export default {
  name: "BasicToolTip",
  props:{
    description: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: "mdi-information-outline"
    },
    color: {
      type: String,
      default: "primary"
    }
  },
  methods: {
    
  }
}
</script>